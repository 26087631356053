<template>
  <div
    id="map"
    ref="map"
    class="six-map"></div>
</template>

<script>
import "leaflet/dist/leaflet.css";
import leaflet from "leaflet";
import markerIcon from "../media/icons/marker.js";

export default {
  name: "SixMap",

  props: {
    markers: {
      /**
       * The markers to display on the map.
       * The key is the name of the marker and the value is the coordinates.
       * Schema:
       * {
       *  [name: string]: [latitude: number, longitude: number]
       * }
       */
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      devicesLayer: null,
      map: null,
    };
  },

  watch: {
    markers: {
      handler() {
        this.updateMarkers();
      },
      deep: true,
    },
  },

  mounted() {
    // Prepare the map and add the markers
    this.map = leaflet.map(this.$refs.map).setView([25, 0], 2);
    this.map.removeControl(this.map.zoomControl);
    this.map.removeControl(this.map.attributionControl);
    L.control.zoom({position: "topright"}).addTo(this.map);

    leaflet
      .tileLayer("http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}&hl=en", {
        maxZoom: 15,
        subdomains: ["mt0", "mt1", "mt2", "mt3"],
      })
      .addTo(this.map);
    this.devicesLayer = leaflet.layerGroup().addTo(this.map);
    this.updateMarkers();
  },

  methods: {
    updateMarkers() {
      if (this.devicesLayer) {
        this.devicesLayer.clearLayers();
      }
      for (const [name, coordinates] of Object.entries(this.markers)) {
        if (coordinates.some((coord) => coord == null)) {
          continue;
        }
        let deviceMarker = leaflet
          .marker(coordinates, {
            icon: leaflet.divIcon({
              className: "", // to remove the default icon
              iconSize: [0, 0],
              iconAnchor: [11, 6],
              popupAnchor: [0, 0],
              html: markerIcon,
            }),
          })
          .addTo(this.devicesLayer);
        deviceMarker.bindTooltip(name, {
          direction: "top",
          offset: [4, -10],
        });
      }
    },
    flyTo(data) {
      let coordinates = data.slice();
      this.map.flyTo(coordinates, 14, {
        animate: true,
        duration: 5,
      });
    },
  },
};
</script>

<style>
.six-map {
  width: 100%;
  height: 500px;
}

.leaflet-tooltip-pane .leaflet-tooltip {
  padding: 12px 16px;

  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  background-color: #fff;
  border-radius: 8px;
}

.leaflet-tooltip-pane .leaflet-tooltip::before {
  content: "";
  position: absolute;
  bottom: 5px;
  left: 50%;
  margin-left: -10px;
  border-width: 10px 12px 0;
  border-style: solid;
  border-color: rgba(255, 255, 255) transparent;
}

.leaflet-bar {
  margin-top: 50% !important;
  margin-right: 50% !important;
  border: none !important;
}

.leaflet-bar a {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
  padding-top: 2px !important;
  width: 40px !important;
  height: 40px !important;
  text-align: center !important;
  font-family: "Inter" !important;
  font-style: normal !important;
  font-weight: 200 !important;
  font-size: 40px !important;
}

.leaflet-bar a.leaflet-control-zoom-in,
.leaflet-bar a.leaflet-control-zoom-out {
  color: var(--color-primary-solid);
  background-color: #fff;
}
</style>
