var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"select-container",style:({width: _vm.fullWidth ? '100%' : 'fit-content'})},[(_vm.label && !_vm.leftLabel)?_c('div',{staticClass:"top-label sm-text"},[_vm._v(" "+_vm._s(_vm.label)+" ")]):_vm._e(),_c('div',{staticClass:"box",style:({
      border: _vm.flat
        ? 'none'
        : _vm.isSelectOpen
        ? '1px solid #034860'
        : _vm.errorMessage
        ? '1px solid var(--color-danger)'
        : '1px solid #E9ECEF',
    }),on:{"click":_vm.toggleSelect,"mouseover":_vm.onHover,"mouseleave":_vm.onHoverEnd}},[(_vm.label && _vm.leftLabel)?_c('div',{staticClass:"uih6 grey",class:[{'left-label': _vm.leftLabel}]},[_vm._v(" "+_vm._s(_vm.label)+" ")]):_vm._e(),_c('div',{staticClass:"selected-items",class:{'disabled': _vm.disabled, 'with-left-label': _vm.leftLabel}},[(_vm.selectedItems.length === 0)?_c('div',{staticClass:"uih6"},[_vm._v(" "+_vm._s(_vm.placeholder)+" ")]):_vm._e(),_vm._l((_vm.selectedItems),function(item,index){return _c('div',{key:index,staticClass:"selected-item",class:{multiple: _vm.multiple},on:{"click":function($event){_vm.multiple && _vm.clickToRemove ? _vm.removeItem(item) : null}}},[(_vm.multiple && _vm.clickToRemove)?_c('six-icon',{staticClass:"item-icon remove",style:({transform: 'rotate(45deg)'}),attrs:{"name":"plusSign","color":_vm.disabled ? 'grey' : 'primary'}}):_vm._e(),_c('span',{staticClass:"uih6"},[_vm._v(_vm._s(item.label))]),(_vm.multiple && index !== _vm.selectedItems.length - 1)?_c('span',[_vm._v(",")]):_vm._e()],1)})],2),_c('six-icon',{staticClass:"item-icon",style:({
        transform: _vm.isSelectOpen ? 'rotate(180deg)' : 'rotate(0)',
      }),attrs:{"name":"caret","color":_vm.disabled ? 'grey' : 'primary'}})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isSelectOpen && !_vm.disabled),expression:"isSelectOpen && !disabled"}],staticClass:"select",style:({marginTop: _vm.isSelectOpen ? '8px' : '0'}),attrs:{"id":"select"}},[_c('transition',{attrs:{"name":"select-fade"}},[_c('div',{staticClass:"options-wrapper"},[(_vm.isSelectOpen)?_c('div',[(_vm.searchable)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchText),expression:"searchText"}],staticClass:"search-input",attrs:{"type":"text","placeholder":"Search"},domProps:{"value":(_vm.searchText)},on:{"input":function($event){if($event.target.composing)return;_vm.searchText=$event.target.value}}}):_vm._e(),_vm._l((_vm.getDisplayOptions()),function(item,index){return _c('div',{key:index,staticClass:"uih6",class:{selected: _vm.isSelected(item)},on:{"click":function($event){return _vm.handleOptionClick(item)},"mouseover":function($event){return _vm.onItemHover(item)},"mouseleave":_vm.onItemHoverEnd}},[_vm._v(" "+_vm._s(_vm.shownItemLabel(item))+" ")])})],2):_vm._e()])])],1),_c('div',{staticClass:"error-message"},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")])])
}
var staticRenderFns = []

export { render, staticRenderFns }