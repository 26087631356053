export function hasPermission(resourceAction, customer) {
  const {member_of, acting_as} = customer;

  // If not acting as someone else, original user has all permissions
  if (!acting_as) {
    return true;
  }

  // Find the member_of entry for the acting_as email
  const member = member_of.find((member) => member.email === acting_as);

  // If the member is not found, assume no permissions are granted
  if (!member) {
    return false;
  }

  // Check if the permission is explicitly granted
  return member.permissions.includes(resourceAction);
}
