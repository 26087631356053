var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"progress-container"},[(!_vm.innerLabel)?_vm._t("label"):_vm._e(),_c('div',{staticClass:"progress-bar",style:({
      height: _vm.sizeAsPx,
      background: _vm.bgColor,
    })},[_c('div',{class:['progress-bar-inner', {indeterminate: _vm.indeterminate}],style:({
        width: `${_vm.percentage}%`,
        background: _vm.progressColor,
      })},[(_vm.innerLabel)?_vm._t("label"):_vm._e()],2)])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }