// custom vue2 progress bar component

<template>
  <div class="container">
      <div class="sf-progress__label">{{label}}</div>
    <div class="sf-progress">
      <div class="sf-progress__bar" :style="{ width: value + '%', backgroundColor:color}"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SfProgress",
  props: {
    value: {
      type: Number,
      default: 0   ,
    },
    label: {
      type: String,
      default: "",
    },
    color: {
      type: String,
      default: "#ff6a00",
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.sf-progress {
  width: 100%;
  height: 10px;
  background-color: #e0e0e0;
  border-radius: 10px;
  overflow: hidden;
  &__bar {
    height: 100%;
  }

}
</style>
