<template>
  <div class="calendar-container">
    <!-- Input Box -->
    <div
      class="selected-date-box"
      @click="toggleCalendar"
      :class="{disabled: disabled}">
      <div>
        <span
          class="uih6 grey"
          v-if="selectedDate"
          >{{ formattedSelectedDate }}</span
        >
        <span
          class="uih6 grey"
          v-else
          >{{ label }}</span
        >
      </div>
    </div>

    <!-- Calendar Part -->
    <div
      class="calendar"
      :class="{visible: isCalendarVisible, solid: solid}">
      <!-- Calendar Header -->
      <div class="header">
        <six-icon
          @click="previousMonth"
          name="doubleLeftArrow"
          clickable>
        </six-icon>
        <span
          v-if="!showMonthSelector && !showYearSelector"
          @click="showMonthSelector = !showMonthSelector"
          style="cursor: pointer">
          {{ formattedNavigatedDate.month }} {{ formattedNavigatedDate.year }}
        </span>
        <span
          style="cursor: pointer"
          v-if="showMonthSelector || showYearSelector"
          @click="
            (showYearSelector = !showYearSelector),
              (showMonthSelector = !showMonthSelector)
          ">
          {{ formattedNavigatedDate.year }}
        </span>
        <six-icon
          @click="nextMonth"
          name="doubleRightArrow"
          clickable>
        </six-icon>
      </div>

      <!-- month selector -->
      <div
        class="months"
        v-if="showMonthSelector">
        <div
          v-for="month in months"
          :key="month.key"
          class="month"
          :class="{navigated: month.key === selectedMonth}"
          @click="navigateMonth(month.key)">
          <span>
            {{ month.value }}
          </span>
        </div>
      </div>

      <!-- year selector -->
      <div v-if="showYearSelector">
        <div>
          <ul class="year-list">
            <li
              v-for="year in years"
              :key="year"
              @click="navigateYear(year)"
              :class="{navigated: year === selectedYear}">
              {{ year }}
            </li>
          </ul>
        </div>
      </div>

      <!-- days of the month -->
      <div v-if="!showMonthSelector && !showYearSelector">
        <div class="days">
          <div
            v-for="day in days"
            :key="day">
            {{ day }}
          </div>
        </div>
        <div class="dates">
          <div
            v-for="date in calendarDays"
            :key="date.id"
            @click="selectDate(date)"
            :class="{
              'selected': date.selected,
              'today': date.today,
              'outside-month': date.outsideMonth,
            }">
            {{ date.day ? date.day : "" }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SixDateInput",

  components: {
    SixIcon: () => import("./sixIcon.vue"),
  },

  data() {
    return {
      currentDate: new Date(),
      selectedDate: null,
      showYearSelector: false,
      showMonthSelector: false,
      selectedYear: null,
      selectedMonth: null,
      isCalendarVisible: false,
      navigatedDate: new Date(),
    };
  },

  props: {
    disabled: {
      type: Boolean,
      default: false,
    },

    label: {
      type: String,
      default: "Select a date",
    },

    solid: {
      // If false, the background will be transparent
      type: Boolean,
      default: true,
    },

    initialValue: {
      default: null,
    },
  },

  computed: {
    formattedSelectedDate() {
      // To show in the input box
      return this.selectedDate
        ? this.selectedDate.toLocaleDateString("en-US", {
            month: "numeric",
            day: "numeric",
            year: "numeric",
          })
        : "";
    },

    formattedNavigatedDate() {
      // To show in the calendar header
      return {
        month: this.navigatedDate.toLocaleDateString("en-US", {
          month: "long",
        }),
        year: this.navigatedDate.getFullYear(),
      };
    },

    days() {
      // TODO: Start of the day is open to discussion
      return ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    },

    months() {
      return [
        {
          key: "January",
          value: "Jan",
        },
        {
          key: "February",
          value: "Feb",
        },
        {
          key: "March",
          value: "Mar",
        },
        {
          key: "April",
          value: "Apr",
        },
        {
          key: "May",
          value: "May",
        },
        {
          key: "June",
          value: "Jun",
        },
        {
          key: "July",
          value: "Jul",
        },
        {
          key: "August",
          value: "Aug",
        },
        {
          key: "September",
          value: "Sep",
        },
        {
          key: "October",
          value: "Oct",
        },
        {
          key: "November",
          value: "Nov",
        },
        {
          key: "December",
          value: "Dec",
        },
      ];
    },

    years() {
      // TODO: Year range is open to discussion
      const currentYear = new Date().getFullYear();
      const years = [];
      for (let i = currentYear - 20; i <= currentYear + 50; i++) {
        years.push(i);
      }
      return years;
    },

    calendarDays() {
      // These are the days that will be shown in the calendar
      const firstDayOfMonth = new Date(
        this.navigatedDate.getFullYear(),
        this.navigatedDate.getMonth(),
        1
      );
      const lastDayOfMonth = new Date(
        this.navigatedDate.getFullYear(),
        this.navigatedDate.getMonth() + 1,
        0
      );

      const startOffset = firstDayOfMonth.getDay();
      const endOffset = 6 - lastDayOfMonth.getDay();

      const startDate = new Date(firstDayOfMonth);
      startDate.setDate(startDate.getDate() - startOffset);

      const endDate = new Date(lastDayOfMonth);
      endDate.setDate(endDate.getDate() + endOffset);

      const dates = [];
      let current = new Date(startDate);

      while (current <= endDate) {
        const dateObject = {
          id: current.getTime(),
          day: current.getDate(),
          selected: this.isSelectedDate(current),
          today: this.isToday(current),
          outsideMonth: current.getMonth() !== this.navigatedDate.getMonth(),
        };
        dates.push(dateObject);
        current.setDate(current.getDate() + 1);
      }

      return dates;
    },
  },

  created() {
    this.selectedMonth = this.currentDate.toLocaleDateString("en-US", {
      month: "long",
    });
    this.selectedYear = this.currentDate.getFullYear();

    if (this.initialValue) {
      if (this.initialValue instanceof Date) {
        this.selectedDate = this.initialValue;
      } else {
        this.selectedDate = new Date(this.initialValue * 1000); // Unix timestamp in ms
      }
    }
  },

  methods: {
    toggleCalendar() {
      if (this.disabled) return;
      this.isCalendarVisible = !this.isCalendarVisible;
    },

    nextMonth() {
      const newDate = new Date(this.navigatedDate);
      newDate.setMonth(newDate.getMonth() + 1);
      this.navigatedDate = newDate;
    },

    previousMonth() {
      const newDate = new Date(this.navigatedDate);
      newDate.setMonth(newDate.getMonth() - 1);
      this.navigatedDate = newDate;
    },

    selectDate(date) {
      if (!date.day) return;
      this.selectedDate = new Date(date.id);
      this.$emit("input", this.selectedDate);
      this.isCalendarVisible = false;
    },

    isSelectedDate(date) {
      return (
        this.selectedDate &&
        date.getFullYear() === this.selectedDate.getFullYear() &&
        date.getMonth() === this.selectedDate.getMonth() &&
        date.getDate() === this.selectedDate.getDate()
      );
    },

    isToday(date) {
      const today = new Date();
      return (
        date.getFullYear() === today.getFullYear() &&
        date.getMonth() === today.getMonth() &&
        date.getDate() === today.getDate()
      );
    },

    navigateMonth(month) {
      this.selectedMonth = month;
      const monthIndex = this.months.findIndex(
        (month) => month.key === this.selectedMonth
      );

      if (monthIndex !== -1) {
        let newDate = new Date(this.navigatedDate);
        newDate.setMonth(monthIndex);
        this.navigatedDate = newDate;
      }

      this.showMonthSelector = false;
    },

    navigateYear(year) {
      this.selectedYear = year;
      let newDate = new Date(this.navigatedDate);
      newDate.setFullYear(this.selectedYear);
      this.navigatedDate = newDate;
      this.showYearSelector = false;
    },
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

.calendar-container {
  font-family: "Inter", sans-serif;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-height: 360px;
}

.selected-date-box {
  cursor: pointer;
  border-radius: 4px;
  background: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 16px;
  min-width: 344px;
  border-radius: 4px;
  border: 1px solid var(--color-deactive);
  line-height: normal;
  height: 37.5px;
}

.disabled {
  cursor: not-allowed;
  background: #f5f5f5;
  opacity: 0.5;
}

.calendar {
  position: absolute;
  top: 88px;
  z-index: 999;
  border-radius: 8px;
  padding: 12px;
  min-height: 360px;
  border: 1px solid #f5f5f5;
  background: #ffffff17;
  box-shadow: 0px 4px 11.6px 0px rgba(0, 0, 0, 0.15);
  display: none;
}
.calendar.visible {
  display: block;
}
.solid {
  background: #fff;
}

.header {
  background-color: #fff;
  color: black;
  text-align: center;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #212529;
  text-align: center;
  font-family: "Inter", sans-serif;
  font-variant-numeric: lining-nums tabular-nums;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  background: #ffffff17;
}

.header button {
  background: none;
  border: none;
  color: black;
  font-size: 16px;
  cursor: pointer;
}
.header__date {
  display: flex;
  flex-direction: row;
  gap: 5px;
}

.months {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
  color: var(--color-deactive-text);
  text-align: center;
  margin: auto;
  margin-top: 25%;
}

.month {
  padding: 10px;
  border-radius: 8px;
}

.month:hover {
  cursor: pointer;
  background: var(--color-deactive);
}

.navigated {
  background: var(--color-deactive);
}

.days {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  text-align: center;
  font-weight: bold;
  color: var(--color-deactive-text);
  text-align: center;
  font-variant-numeric: lining-nums tabular-nums;
  font-family: "Inter", sans-serif;

  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.days div {
  padding: 5px;
}

.dates {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 5px;
  color: var(--color-deactive-text);
  text-align: center;
}

.dates div {
  cursor: pointer;
  text-align: center;
  text-align: center;
  font-variant-numeric: lining-nums tabular-nums;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  width: 40px;
  height: 40px;
  flex-direction: column;
  justify-content: center;
  border-radius: 8px;
}

/* To apply style to days on hover */
.dates div:hover {
}

.selected {
  background: var(--color-deactive);
}

/* To apply style to days that don't belong current month */
.outside-month {
  color: #adb5bd;
}

/* To apply a custom style to todays date */
.today {
}

.year-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: 300px;
  overflow-y: auto;
  color: var(--color-deactive-text);
}
ul {
  list-style: none;
  padding: 0;
}
.year-list li {
  padding: 8px;
  cursor: pointer;
  border-radius: 8px;
}
.year-list li::before {
  all: initial;
}
.year-list li:hover {
  background-color: var(--color-deactive);
}
</style>
