<template>
  <div
    id="app"
    class="app">
    <sf-header> </sf-header>
    <router-view />
    <six-toast-container></six-toast-container>
  </div>
</template>

<script>
import SfHeader from "../components/header/Header.vue";
import SixToastContainer from "../six-ui/components/sixToastContainer.vue";

export default {
  name: "app",
  components: {SfHeader, SixToastContainer},
};
</script>

<style lang="scss">
@import "../sass/main.scss";

body {
  height: 100%;

  #app {
    height: 100%;
  }
}
</style>
