var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.isSvg)?_c('div',{staticClass:"icon",style:({
    ..._vm.colors[_vm.color],
    height: _vm.scaledHeight + 'px',
    width: _vm.scaledWidth + 'px',
    cursor: _vm.cursor,
    opacity: _vm.disabled ? 0.2 : 1,
  }),attrs:{"title":_vm.tooltip},domProps:{"innerHTML":_vm._s(_vm.getIconHtml())},on:{"click":_vm.handleClick,"mouseover":_vm.handleMouseOver,"mouseleave":_vm.handleMouseLeave}}):_c('img',{attrs:{"src":_vm.iconSrc,"title":_vm.tooltip},on:{"click":_vm.handleClick,"mouseover":_vm.handleMouseOver,"mouseleave":_vm.handleMouseLeave}})
}
var staticRenderFns = []

export { render, staticRenderFns }