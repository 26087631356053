<template>
  <div
    :class="cardClasses"
    :style="{background: background}">
    <!--Title part-->
    <div class="card-title">
      <slot name="title"></slot>

      <!--Beta tag part-->
      <div class="beta-container">
        <div
          v-if="beta"
          class="beta-tag">
          Beta
        </div>
      </div>
    </div>
    <!--Info icon and tooltip part-->
    <div
      v-if="showInfo"
      class="info-icon">
      <six-icon name="info"> </six-icon>
      <div class="tooltip">
        <slot name="info"></slot>
      </div>
    </div>
    <!--Default slot-->
    <div class="card-content">
      <slot></slot>
    </div>
    <!--Loading overlay part-->
    <div
      v-if="loading"
      class="loading-overlay">
      <div class="spinner"></div>
    </div>
    <!-- Disabled overlay part -->
    <div
      v-if="disabled && !loading"
      class="disabled-overlay">
      <div class="disabled-wrapper">
        <slot name="on-disabled"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import SixIcon from "../components/sixIcon.vue";

export default {
  name: "SixCard",

  components: {SixIcon},

  props: {
    background: {
      type: String,
      default: "#ffffff",
    },
    beta: {
      // Whether to show the beta tag
      type: Boolean,
      default: false,
    },
    disabled: {
      // Whether to show the disabled overlay,
      // if true, the card will be disabled and blurred out,
      // on-disabled slot's content will be shown
      type: Boolean,
      default: false,
    },
    noPadding: {
      type: Boolean,
      default: false,
    },
    loading: {
      // Whether to show the loading spinner with blur overlay
      type: Boolean,
      default: false,
    },
  },

  computed: {
    cardClasses() {
      return {
        "six-card": true,
        "no-padding": this.noPadding,
        "disabled": this.disabled,
      };
    },
    showInfo() {
      return this.$slots.info !== undefined;
    },
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

.six-card {
  position: relative;
  color: var(--color-text);
  border-radius: 8px;
  box-shadow: 0px 10px 13px 0px rgba(17, 38, 146, 0.05);
  padding: 24px;
  font-family: "Inter", sans-serif;

  &.no-padding {
    padding: 0;
  }

  .card-title {
    display: flex;
    justify-content: space-between;
  }

  .card-content {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
}

.info-icon {
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  margin: 16px;

  .tooltip {
    max-width: 400px;

    color: var(--color-deactive-text);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    position: absolute;
    top: -80px;
    right: -70px;
    background-color: #ffffff;
    padding: 12px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    z-index: 10;
    display: none !important;
  }

  &:hover .tooltip {
    display: block !important;
  }
}

.beta-container {
  position: absolute;
  overflow: hidden;
  top: 0px;
  left: 0px;
  width: 75px;
  height: 75px;
}

.beta-tag {
  position: absolute;
  left: -20px;
  top: 7px;
  width: 80px;
  height: 25px;

  background: var(--color-danger);

  text-align: center;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;

  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */

  z-index: 999;
  color: #fff;
  line-height: 27px;
  transform: rotate(-45deg);
}

.disabled-overlay {
  position: absolute;
  overflow: hidden;
  border-radius: 8px;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
  cursor: not-allowed;
}

.disabled-wrapper {
  width: 100%;
  height: 100%;
  -webkit-backdrop-filter: blur(7px);
  backdrop-filter: blur(7px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 9999999;
}

.loading-overlay {
  position: absolute;
  overflow: hidden;
  border-radius: 8px;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-backdrop-filter: blur(7px);
  backdrop-filter: blur(7px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid var(--color-primary-solid);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin: 0 auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 1440px) {
  .six-card {
    padding: 20px;
  }
}
</style>
