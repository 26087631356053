import { render, staticRenderFns } from "./sixCard.vue?vue&type=template&id=58f25a00&scoped=true&"
import script from "./sixCard.vue?vue&type=script&lang=js&"
export * from "./sixCard.vue?vue&type=script&lang=js&"
import style0 from "./sixCard.vue?vue&type=style&index=0&id=58f25a00&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "58f25a00",
  null
  
)

export default component.exports