import axios from '../../app/Api';
import router from '../../router';
import jwtDecoder from 'jwt-decode'
import initAuth0Client from "../../auth"

const state = {
  userInfo: null,
  backofficeToken: localStorage.getItem('t-backoffice') || '',
  status: '',
  accountSuspended: undefined,
};

const getters = {
  isAuthenticated: (state) => !!state.userInfo,
  authStatus: (state) => state.status,
  backofficeToken: (state) => state.backofficeToken,
  decodedBackofficeToken: (state) => {
    try{
      return jwtDecoder(state.backofficeToken)
    }
    catch (e){
      return undefined
    }
  },
  customer: (state) => state.userInfo,
  isAccountSuspended: (state) => state.accountSuspended
};

const mutations = {
  AUTH_REQUEST: (state) => {
    state.status = 'loading';
  },
  AUTH_SUCCESS: (state, userInfo) => {
    state.status = 'success';
    state.userInfo = userInfo;
  },
  AUTH_ERROR: (state) => {
    state.status = 'error';
  },
  AUTH_LOGOUT: (state) => {
    state.userInfo = null;
  },
  APPROVE_INVITATION_SUCCESS: (state, invitation) => {
    state.userInfo.member_of.push({
      email: invitation.email,
      permissions: invitation.permissions,
    });
    state.userInfo.pending_invitations_from = state.userInfo.pending_invitations_from.filter(
      (pendingInvitation) => pendingInvitation.email !== invitation.email
    );
  },
  REJECT_INVITATION_SUCCESS: (state, email) => {
    state.userInfo.pending_invitations_from = state.userInfo.pending_invitations_from.filter(
      (invitation) => invitation.email !== email
    );
  },
  REMOVE_TEAM_MEMBER: (state, email) => {
    if (state.userInfo) {
      state.userInfo.team_members = state.userInfo.team_members.filter(
        (member) => member.email !== email
      );
    }
  },
  UPDATE_TEAM_MEMBER_PERMISSIONS: (state, { email, permissions, tags }) => {
    if (state.userInfo) {
      const member = state.userInfo.team_members.find(
        (member) => member.email === email
      );
      if (member) {
        member.permissions = permissions;
        member.tags = tags;
      }
    }
  },

  updateSuspensionStatus: (state, value) => {
    state.accountSuspended = !!value
  },

  BACKOFFICE_LOGOUT: (state) => {
    state.backofficeToken = '';
  },
  BACKOFFICE_LOGIN: (state, token) => {
    state.backofficeToken = token;
  }
};

const actions = {
  auth_login: ({ commit, dispatch }, user) => new Promise(async (resolve, reject) => { // The Promise used for router redirect in login
    commit('AUTH_REQUEST');
    try {
      const resp = await axios({ url: 'auth/login', data: user, method: 'POST' })

      if (resp.status !== 200) {
        reject(resp);
        return;
      }
      const user_resp = await axios({ url: 'auth/user_info', method: 'GET'})
      commit('AUTH_SUCCESS', user_resp.data);
      resolve(resp);
    
    } catch (err) {
      commit('AUTH_ERROR', err);
      reject(err);
    }
  }),

  auth_logout: ({ commit, dispatch }, isManuel = false) => new Promise((resolve, reject) => {
    const clear_tokens = async () => {
      commit('AUTH_LOGOUT');
      commit ('BACKOFFICE_LOGOUT');
      localStorage.removeItem('t-backoffice');
      
      let auth0Client = await initAuth0Client()
      
      auth0Client.logout({
        returnTo: window.location.origin,
      });

    };

    if (isManuel) {
      axios({ url: 'auth/logout', method: 'POST' }).finally(() => {
        clear_tokens();
      });
    } else {
      clear_tokens();
    }
  }),

  auth_register: ({ commit, dispatch }, userData) => new Promise((resolve, reject) => {
    axios.post('/auth/register', userData)
      .then((resp) => {
        if (resp.status === 200) {
          resolve(resp);
        } else if (resp.status === 409) {
          reject(resp);
        }
      });
  }),

  rejectInvitation: ({ commit }, email) => {
    return new Promise((resolve, reject) => {
      axios
        .post("/tenancy/reject_invitation", { inviter_email: email })
        .then((response) => {
          if (response.status === 200) {
            commit('REJECT_INVITATION_SUCCESS', email);
            resolve(response);
          } else {
            reject(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  approveInvitation: ({ commit }, email) => {
    return new Promise((resolve, reject) => {
      axios
        .post("/tenancy/approve_invitation", { inviter_email: email })
        .then((response) => {
          if (response.status === 200) {
            commit('APPROVE_INVITATION_SUCCESS', response.data);
            resolve(response);
          } else {
            reject(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  deleteTeamMember: ({ commit }, email) => new Promise((resolve, reject) => {
    axios.delete(`/tenancy/team_member/${email}`)
      .then((response) => {
        if (response.status === 200) {
          commit('REMOVE_TEAM_MEMBER', email);
          resolve(response);
        } else {
          reject(response);
        }
      })
      .catch((error) => {
        reject(error);
      });
  }),

  updateTeamMemberPermissions: ({ commit }, { email, permissions, tags }) => {
    return new Promise((resolve, reject) => {
      axios
        .patch(`/tenancy/invitation`, {
          email,
          permissions,
          tags,
        })
        .then((response) => {
          if (response.status === 200) {
            commit("UPDATE_TEAM_MEMBER_PERMISSIONS", { email, permissions, tags });
            resolve(response);
          } else {
            reject(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  logout_backoffice: ({ commit, dispatch }) => new Promise((resolve, reject) => {
    axios.post("/backoffice/logout", {},
      {
        headers: {
          "X-Backoffice-Token": `${state.backofficeToken}`,
        },
      }
    )
    .then((resp) => {
      if (resp.status === 200) {
        commit('BACKOFFICE_LOGOUT');
        localStorage.removeItem('t-backoffice');
        resolve(resp);
      } else {
        reject(resp);
      }
    });
  }),

  login_backoffice:( { commit, dispatch }, token) => new Promise((resolve, reject) => {
    commit('BACKOFFICE_LOGIN', token);
    localStorage.setItem('t-backoffice', token);
    resolve();
  }),
};

export default {
  state,
  getters,
  mutations,
  actions,
};
