// date related //
/**
 * Date formats listed below:
 * semanticDate: "Oct 12, 2021"
 * semanticDateTime: "Oct 12, 2021 12:00 PM"
 * fullDateTime: "2024-05-14 00:00" || "YYYY-MM-DD HH:MM"
 * TODO: to be continued...
 *
 */

// byte formatting //

/**
 * Returns semantic byte size of given amount of bytes where
 * 1 GB = 1000 MB
 * @param {number} bytes
 * @returns {string} "X.XX GB or X.XX MB or X.XX KB or X.XX B"
 */
export function semanticBytes(bytes) {
  if (parseFloat(bytes) == NaN) return "0 B";
  if (bytes === 0) return "0 B";
  const k = 1000;
  const dm = 2;
  const sizes = ["B", "KB", "MB", "GB", "TB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}

// other//

/**
 * Copies given text to clipboard, shows a toast message by default
 *
 * @param {String} text
 * @param {String} toastMessage - Default is "Copied to clipboard"
 * @param {boolean} showToast - Default is true
 */
export function copyToClipboard(
  text,
  vueInstance,
  toastMessage = "Copied to clipboard"
) {
  navigator.clipboard.writeText(text);
  if (
    toastMessage !== "" &&
    toastMessage !== null &&
    vueInstance != undefined
  ) {
    vueInstance.sixToast({
      message: toastMessage,
      type: "success",
      title: "Success",
      timeout: 2000,
    });
  }
}

/**
 * Returns semantic asset type of given asset type
 * @param {String} assetType
 * @returns {String} "Pico LTE" || "Plastic SIM" ||
 *                   "CCC" || "CORE Device + Plastic SIM" || "ALPON X4"
 */

export function semanticAssetType(assetType) {
  let typeTexts = {
    picolte: "Pico LTE",
    plastic_sim: "Plastic SIM",
    core: "CORE Device + Plastic SIM",
    alpon_x4: "ALPON X4",
  };

  return typeTexts[assetType] || assetType;
}

/**
 * Formats activity log text based on metadata and detail.
 * @param {Object} activity - The activity log object.
 * @returns {String} - Formatted activity log text.
 */
export function formatLogText(activity) {
  const {metadata, detail} = activity;
  let text = "";

  switch (metadata.category) {
    case "datapools":
      if (metadata.action === "subscribe") {
        text = `Subscribed to package: ${
          detail.package_name
        } - ${semanticBytes(detail.data_volume)}`;
      } else if (metadata.action === "unsubscribe") {
        text = `Unsubscribed from package: ${
          detail.package_name
        } - ${semanticBytes(detail.data_volume)}`;
      }
      break;
    case "fleets":
      if (metadata.action === "create") {
        text = `Created fleet: ${detail.fleet_name}`;
      } else if (metadata.action === "update") {
        text = `Updated fleet: ${detail.fleet_name}`;
      } else if (metadata.action === "delete") {
        text = `Deleted fleet: ${detail.fleet_name}`;
      }
      break;
    case "billing":
      if (metadata.action === "setup_intent") {
        text = formatSetupIntentText(detail);
      } else if (metadata.action === "delete_payment_method") {
        text = `Deleted a payment method`;
      } else if (metadata.action === "update_preferences") {
        text = `Updated billing preferences`;
      } else if (metadata.action === "add_credits") {
        text = `Added credits: ${detail.amount} from ${detail.source}`;
      }
      break;
    case "assets":
      if (metadata.action === "register") {
        text = `Registered asset: ${detail.asset_name}`;
      } else if (metadata.action === "activate_sim") {
        text = `Activated asset: ${detail.asset_name}`;
      } else if (metadata.action === "inactivate_sim") {
        text = `Deactivated asset: ${detail.asset_name}`;
      } else if (metadata.action === "update_fleet") {
        text = `Updated fleet of asset: ${detail.asset_name}`;
      }
      break;
    case "tags":
      if (metadata.action === "create") {
        text = `Created tag: ${detail.tag_name}`;
      } else if (metadata.action === "update") {
        text = `Updated tag: ${detail.tag_name}`;
      } else if (metadata.action === "delete") {
        text = `Deleted tag: ${detail.tag_name}`;
      }
      break;
    case "core":
      if (metadata.action === "create_bulk_deployment") {
        text = `Created bulk deployment: ${detail.deployment_name}`;
      } else if (metadata.action === "update_bulk_deployment") {
        text = `Updated bulk deployment: ${detail.deployment_name}`;
      }
      break;
    default:
      text = `Performed action ${metadata.category}.${metadata.action}`;
  }

  return text;
}

/**
 * Helper function to format setup intent text based on status.
 * @param {Object} detail - The detail object containing setup intent status.
 * @returns {String} - Formatted setup intent text.
 */
export function formatSetupIntentText(detail) {
  let text = "Setup intent";

  switch (detail.setup_intent_status) {
    case "requires_payment_method":
      text += " requires a payment method.";
      break;
    case "requires_confirmation":
      text += " requires confirmation.";
      break;
    case "requires_action":
      text += " requires additional action.";
      break;
    case "processing":
      text += " is processing.";
      break;
    case "requires_capture":
      text += " requires capture.";
      break;
    case "canceled":
      text += " was canceled.";
      break;
    case "succeeded":
      text += ` succeeded with payment method ${detail.payment_method}.`;
      break;
    default:
      text += ` has an unknown status: ${detail.setup_intent_status}.`;
  }

  return text;
}
