<template>
  <transition name="modal-fade">
    <div
      v-if="isModalVisible"
      class="modal"
      :class="['backdrop-' + backdrop, 'position-' + position]"
      @click="handleBackdropClick">
      <div
        class="modal-content"
        @click.stop>
        <div class="modal-header">
          <h5 class="title bold white">{{ title }}</h5>
          <six-icon
            v-if="closeButton && !loading"
            name="circleClose"
            class="close-icon"
            :height="32"
            :width="32"
            clickable
            @click="closeModal"></six-icon>
        </div>
        <div class="modal-body">
          <div
            v-if="loading"
            class="loading-overlay">
            <div class="loading-group">
              <div class="spinner"></div>
              <slot name="loading-description"></slot>
            </div>
          </div>

          <slot></slot>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import SixIcon from "../components/sixIcon.vue";

export default {
  name: "SixModal",

  components: {
    SixIcon,
  },

  props: {
    title: {
      type: String,
      default: "Modal Title",
    },
    loading: {
      type: Boolean,
      default: false,
    },
    closeButton: {
      /**
       * If true, header will have a close icon on top right corner
       */
      type: Boolean,
      default: true,
    },
    backdrop: {
      type: String,
      default: "opaque",
      validator: (value) =>
        ["blurred", "opaque", "transparent"].includes(value),
    },
    dismissable: {
      /**
       * If true, user can click outside of the modal to close the modal
       */
      type: Boolean,
      default: true,
    },
    position: {
      type: String,
      default: "top-center",
      validator: (value) =>
        ["center", "top-center", "bottom-center"].includes(value),
    },
  },

  data() {
    return {
      isModalVisible: false,
    };
  },

  watch: {
    isModalVisible(newValue) {
      this.$emit(newValue ? "open" : "close");
    },
  },

  methods: {
    show() {
      this.isModalVisible = true;
    },
    hide() {
      this.isModalVisible = false;
    },
    toggle() {
      this.isModalVisible = !this.isModalVisible;
    },
    handleBackdropClick() {
      if (this.dismissable && !this.loading) {
        this.hide();
      }
    },
    closeModal() {
      this.hide();
    },
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2147483546;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity 0.22s;
}

.modal-fade-enter,
.modal-fade-leave-to {
  opacity: 0;
}
.modal-content {
  background: #fff;
  border: none;
  border-radius: 12px;
  /* max-width: 800px; */
  min-width: 508px;
  /* width: 100%; */
}

.modal-header {
  padding: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: var(--color-primary-gradient);
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.title {
  text-transform: uppercase;
}

.modal-body {
  position: relative;
  padding: 24px;
}

.modal-footer {
  padding-bottom: 24px;
  padding-right: 24px;
  padding-left: 24px;
  display: flex;
  justify-content: space-between;
  background-color: #fff;
}

.close-icon {
  cursor: pointer;
  width: 32px;
  height: 34px;
  flex-shrink: 0;
}

.backdrop-blurred {
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(3px);
}

.backdrop-opaque {
  background: rgba(0, 0, 0, 0.35);
}

.backdrop-transparent {
  background: rgba(0, 0, 0, 0);
}

.position-center {
  align-items: center;
  justify-content: center;
}

.position-top-center {
  align-items: flex-start;
  justify-content: center;
  padding-top: calc(10vh - 30px);
}

.position-bottom-center {
  align-items: flex-end;
  justify-content: center;
  padding-bottom: calc(10vh - 30px);
}

.loading-overlay {
  position: absolute;
  overflow: hidden;
  border-radius: 8px;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-backdrop-filter: blur(7px);
  backdrop-filter: blur(7px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid var(--color-primary-solid);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin: 0 auto;
}

.loading-description {
  text-align: center;
  margin-top: 16px;
  color: #666;
  font-size: 14px;
}

.loading-group {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 1440px) {
  .six-card {
    padding: 20px;
  }
}

@media screen and (max-height: 900px) {
  .modal {
    overflow: scroll;
  }
}

@media screen and (max-width: 768px) {
  .modal-content {
    min-width: unset;
    width: 90%;
    height: 90%;
    overflow-y: auto;
  }
}
</style>
