<template>
  <va-navbar class="app-navbar">
    <header-selector slot="selector" :minimized.sync="minimizedProxy" />
    <template slot="logo">
      <logo scale="9rem" />
    </template>

    <div v-if="windowWidth < 768" class="row flex-nowrap align--center" style="margin-left: auto;">
      <menu-dropdown
        class="va-navbar__item"
        style="margin-top: .55rem; margin-right: 1rem; width: max-content; height: 2rem; text-align: right;"
      >
        <span class="white-color">{{ $t("navbar.menu") }}</span>
      </menu-dropdown>
    </div>
    <div v-else class="row flex-nowrap align--center" style="margin-left: auto;">
      <a
        target="_blank"
        href="https://docs.sixfab.com/docs/sixfab-core-introduction"
        style="margin-left: auto"
      >
        <span class="white-color">{{ $t("navbar.whatIsSixfabCode") }}</span>
      </a>
      <a
        target="_blank"
        href="https://docs.sixfab.com/docs/sixfab-core-getting-started"
        style="margin-left: 1.25rem; cursor: pointer"
      >
        <span class="white-color">{{ $t("navbar.gettingStarted") }}</span>
      </a>


      <a
        href="/#/pricing"
        style="margin-left: 1.25rem; cursor: pointer"
      >
        <span class="white-color">{{ $t("navbar.pricing") }}</span>
      </a>

      <a
        href="/#/developer"
        style="margin-left: 1.25rem; cursor: pointer"
      >
        <span class="white-color">{{ $t("navbar.developer") }}</span>
      </a>

      <a
        @click="logout()"
        rel="noopener noreferrer"
        target="_blank"
        style="margin-left: 1.25rem; cursor: pointer"
      >
        <span class="white-color">{{ $t("navbar.logout") }}</span>
      </a>
    </div>
  </va-navbar>
</template>

<script>
import VaIconVuestic from "vuestic-ui/src/components/vuestic-components/va-icon/va-iconset/VaIconVuestic";
import HeaderSelector from "./components/HeaderSelector";
import MenuDropdown from "./components/dropdowns/MenuDropdown";

export default {
  name: "app-navbar",
  components: {
    VaIconVuestic,
    HeaderSelector,
    MenuDropdown
  },
  props: {
    minimized: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      windowWidth: window.innerWidth
    };
  },
  mounted() {
    window.onresize = () => {
      this.windowWidth = window.innerWidth;
    };
  },
  computed: {
    minimizedProxy: {
      get() {
        return this.minimized;
      },
      set(minimized) {
        this.$emit("update:minimized", minimized);
      }
    }
  },
  methods: {
    logout() {
      this.$store.dispatch("auth_logout", true);
    }
  }
};
</script>
<style lang="scss">

.router-link-exact-active.va-navbar__logo{
  background-color: transparent !important;
}

.va-navbar{
  background-color: #034860 !important;
}

.va-navbar__shape{
  border-top: none !important;
}

.profile-dropdown__content {
  text-align: left;
}

.va-navbar__center {
  display: none !important;
}

.app-navbar {
  &__button {
    width: 10.8125rem;
    .va-button__content__icon-left.fa-github {
      font-size: 1.5rem;
    }
  }
  &__actions {
    justify-content: flex-end;
  }
  @include media-breakpoint-down(lg) {
    &__button {
      display: none !important;
    }
    .profile-dropdown {
      margin: auto 0 auto 0.75rem;
    }
  }
  @include media-breakpoint-down(md) {
    @at-root .va-navbar__text {
      display: none !important;
    }

    .header-selector[class*="i-menu-collapsed"] {
      margin-top: 0.55rem;
    }
    .va-navbar__logo {
      margin-top: -0.75rem;
    }
  }
  @include media-breakpoint-down(sm) {
    &__button {
      display: none !important;
    }
    &__actions {
      justify-content: space-around;
      padding: 0 0.5rem;
    }
    .profile-dropdown {
      position: absolute;
      right: 0.75rem;
      top: 1.25rem;
      height: fit-content;
      margin: auto;
    }
  }
}

span.white-color {
  color: white;
  font-weight: 400;
}

.va-navbar__logo {
  width: 30vw !important;
  height: auto !important;
  max-width: 150px !important;
}

.va-navbar {
  height: 5rem !important;
}

.va-sidebar {
  top: 5rem !important;
}
</style>
