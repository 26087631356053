<template>
  <div class="select-container">
    <!-- enable search in search boxes -->
    <div class="selected-items" @click="toggleDropdown">
      <div class="placeholder" v-if="selectedItems.length == 0">
        {{ selectBoxPlaceholder }}
      </div>

      <div
        v-for="(item, index) in selectedItems"
        :key="index"
        class="selected-item"
        :class="multiselect ? 'multiselect' : ''"
        @click="removeItem(item)"
      >
        {{ item.label }}

      </div>
      <i class="fa fa-caret-down"></i>
    </div>
    <div :class="['dropdown', {'dropdown-absolute':absolutePosition}]" v-show="isDropdownOpen" id="dropdown"  >
      <input
        v-if="search"
        type="text"
        class="search"
        :placeholder="searchPlaceholder"
        v-model="searchText"
        >
      <div
        v-for="(item, index) in search ? searchedOptions : options "
        :key="index"
        @click="multiselect ? toggleSelected(item) : selectItem(item)"
        :class="isSelected(item) ? 'selected' : '' "


      >
        {{ item.label }}
        

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SfSelect",
  props: {
    options: {
      type: Array,
      required: true,
    },

    selectBoxPlaceholder: {
      type: String,
      default: "Select",
    },
    multiselect: {
      type: Boolean,
      default: false,
    },

    value: {
      type: [String, Array],
      default: "",
    },
    search: {
      type: Boolean,
      default: false,
    },
    searchPlaceholder: {
      type: String,
      default: "Search",
    },
    absolutePosition: {
      type: Boolean,
      default: false,
    },

  },
  data() {
    return {
      isDropdownOpen: false,
      selectedItems: [],
      searchText: "",
      searchedOptions: this.options
    };
  },
  watch: {
    selectedItems() {
      this.$emit(
        "input",
        this.selectedItems.map((item) => item.value)
      );
    },
    searchText(val) {
       this.searchedOptions = this.options.filter((item) =>
        item.label.toLowerCase().includes(val.toLowerCase())
      );
    },
  },
  created() {
    // dropdowns should be closed when clicked outside
    document.addEventListener("click", (e) => {
      if (!this.$el.contains(e.target)) {
        this.isDropdownOpen = false;
      }
    });
  },
  mounted() {
    if (this.multiselect) {
      this.selectedItems = this.options.filter((item) =>
      this.value.includes(item.value)
      );
    } else {
      this.selectedItems= this.options.filter((item) => item.value == this.value);
    }
  },
  methods: {

    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
    },
    selectItem(item) {
      this.selectedItems = [item];
      this.$emit("input", item.value);
      this.isDropdownOpen = false;
    },
    isSelected(item) {
      return this.selectedItems.some(
        (selectedItem) => selectedItem.value == item.value
      );
    },
    toggleSelected(item) {
      let index = this.selectedItems.findIndex(
        (selectedItem) => selectedItem.value == item.value
      );
      if (index === -1) {
        this.selectedItems.push(item);
      } else {
        this.selectedItems.splice(index, 1);
      }
      this.$emit(
        "input",
        this.selectedItems.map((item) => item.value)
      );
      this.isDropdownOpen = true;
    },
    removeItem(item) {
      let index = this.selectedItems.findIndex(
        (selectedItem) => selectedItem.value == item.value
      );
      if (index != -1) {
        this.selectedItems.splice(index, 1);
      }
    },
  },
};
</script>

<style>
.select-container {
  position: relative;
  cursor: pointer;
}
.placeholder {
  color: #757575;
}
.search{
  width: 100%;
  padding: 5px;
  border: 1px solid #ccc;
  border-left:none;
  border-right:none;
  border-top:none;
  margin-top: 5px;
  margin-bottom: 5px;

}
.fa-caret-down {
  margin-left: 15px;
  font-size: 15px;
  float: right;
  position: absolute;
  right: 5px;
}

.selected-items {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px 3px;
  border: 1px solid #ccc;
  border-radius: 8px;
  overflow: hidden;
  cursor: pointer;
}

.selected-item {
  display: flex;
  align-items: center;
  justify-self: center;
  margin-right: 5px;
  padding: 4px 3px;
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 4px;
}
/* put comma after selected items if it's multiselection  */
.multiselect::after {
  content: ",";
  margin-right: 5px;
}

.selected-item .remove {
  margin-left: 5px;
  cursor: pointer;
}
.fa-times {
  font-size: 15px;
}

.dropdown {
  top: 100%;
  left: 0;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  background-color: #fff;
  border: 1px solid #ccc;
  border-top: none;
  border-radius: 4px;
  z-index: 9999;
}
.dropdown-absolute {
  position: absolute;
}

.dropdown div {
  padding: 5px;
  
}


.dropdown div.selected {
  background-color: rgba(51, 51, 51, 0.05);
}



.dropdown div:last-child {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.search-input {
  display: block;
  width: 100%;
  padding: 10px;
  border: none;
  border-bottom: 1px solid #ccc;
  font-size: 16px;
  outline: none;
}

.search-input:focus {
  border-bottom-color: #007bff;
}

/* TODO: change the max-width to your own breakpointa */
@media only screen and (max-width: 768px) {
  .dropdown {
    max-height: 150px;
  }
}
</style>
