<template>
  <div>
    <six-modal
      ref="switchTeamMemberModal"
      title="Switching Team Member"
      :dismissable="false"
      :closeButton="false"
      backdrop="blurred">
      <div class="modal-title">Switching Team Member</div>
      <div class="spinner"></div>
    </six-modal>
  </div>
</template>

<script>
export default {
  name: "SwitchTeamMemberModal",

  methods: {
    show() {
      this.$refs.switchTeamMemberModal.show();
    },
    hide() {
      this.$refs.switchTeamMemberModal.hide();
    },
  },
};
</script>

<style scoped>
.modal-title {
  display: flex;
  justify-content: center;
  color: var(--text-color);
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 16px;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid var(--color-primary-solid);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin: 0 auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
