var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"button-wrapper"},[_c('div',{class:[
      'six-button',
      _vm.colorClass,
      _vm.sizeClass,
      {
        fullWidth: _vm.fullWidth,
        loading: _vm.loading,
        disabled: _vm.disabled,
        outlined: _vm.outlined,
        round: _vm.round,
      },
    ],attrs:{"disabled":_vm.loading || _vm.disabled},on:{"click":_vm.handleClick}},[(_vm.loading)?_c('span',[_vm._v("Loading...")]):_c('span',{staticClass:"slot-container"},[(_vm.icon)?_c('six-icon',{staticClass:"container-icon",attrs:{"name":_vm.icon}}):_vm._e(),_vm._t("default")],2)]),(_vm.popover && _vm.popoverText)?_c('div',{staticClass:"popover"},[_vm._v(" "+_vm._s(_vm.popoverText)+" ")]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }