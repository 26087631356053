<template>
  <div>
    <sf-button @click.native="showFilterModal = true">
      Filter Assets
    </sf-button>
    <va-modal
      v-model="showFilterModal"
      class="filter-modal"
      hide-default-actions
      size="large"
    >
      <template>
        <div class="filter-modal-header">
          <h2>Filter Assets</h2>
        </div>
        <div class="filter-container">
          <div class="filter-option">
            <div class="filter-option-title">
              <h2>Asset Type</h2>
            </div>
            <div class="filter-option-choices">
              <!-- <select v-model="filters.type">
                <option
                  v-for="(option, index) in typeOptions"
                  :value="option.value"
                  :key="index"
                >
                  {{ option.label }}
                </option>
              </select> -->
              <sf-select
                :options="typeOptions"
                :value="filters.type"
                @input="handleFilterChange('type',$event)"
              ></sf-select>
            </div>
          </div>

          <div class="filter-option" v-if="filters.type == 'plastic_sim'">
            <div class="filter-option-title">
              <h2>Sim Status</h2>
            </div>
            <div class="filter-option-choices">
              <!-- <select v-model="filters.simStatus">
                <option
                  v-for="option in simStatusOptions"
                  :value="option.value"
                  v-bind:key="option.value"
                >
                  {{ option.label }}
                </option>
              </select> -->
              <sf-select
                :options="simStatusOptions"
                :value="filters.simStatus"
                @input="handleFilterChange('simStatus',$event)"
               
              >
              </sf-select>
            </div>
          </div>

          <div class="filter-option" v-if="tagFilteringEnabled">
            <div class="filter-option-title">
              <h2>Tags</h2>
            </div>
            <div class="filter-option-choices">
              <!-- multiselect tags -->
              <sf-select
              @input="handleFilterChange('tag',$event)"
                :value="filters.tag"
                :options="
                  allTagsOfCustomer.map((tag) => ({
                    value: tag.name,
                    label: tag.name,
                  }))
                "
                :multiselect="true"
                :search="true"
                :searchPlaceholder="'Search Tags'"
              ></sf-select>
            </div>
          </div>

            <div class="filter-modal-actions">
              <sf-button class="filter-button" @click.native="clearFilters()"
                >Clear Filters</sf-button
              >
              <sf-button
                class="filter-button"
                color="green"
                @click.native="filterAssets(filters)"
              >
                Apply Filters
              </sf-button>
            </div>
        </div>
      </template>
    </va-modal>
  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  data() {
    return {
      showFilterModal: false,
      typeOptions: [
        { value: "plastic_sim", label: "Plastic SIM" },
        { value: "core_device", label: "Core Device" },
        { value: "picolte", label: "Pico LTE" },
        // to be continued..
        // { value: "ccc", label: "CCC" }, 
      ],

      simStatusOptions: [
        { value: "active", label: "Active" },
        { value: "inactive", label: "Inactive" },
      ],

    };
  },

  props: {
    allTagsOfCustomer: {
      type: Array,
      default: () => [],
    },
    tagFilteringEnabled: {
      type: Boolean,
      default: true,
    },
  },
  watch: {
    "filters.type"(val) {
      if (val != "plastic_sim") this.filters.simStatus = "";
    },
  },

computed:{
  // get filters from store
   ...mapState({
    filters: (state) => state.app.assetFilters,
},)
},
  
  methods: {
    filterAssets(filters) {
      this.$emit("filter", {
        filters: filters
      });
      this.$store.commit("setFilters", filters);

      this.showFilterModal = false;
    },
    clearFilters() {
      let filters = {
        tag: [],
        type: "",
        simStatus: "",
      };
      this.$store.commit("setFilters", filters);
      this.filterAssets(filters);
    },
    handleFilterChange(filter_name,value) {
      let filters = {};
   
        if (filter_name == "type") {
        filters = {
          ...this.filters,
          type: value,
        };
      } else if (filter_name == "simStatus") {
        filters = {
          ...this.filters,
          simStatus: value,
        };
      } else if (filter_name == "tag") {
        filters = {
          ...this.filters,
          tag: value,
        };
      }
      this.$store.commit("setFilters", filters);

    },


  },
};
</script>

<style scoped >
/* make modal bigger in height */
.filter-modal-header  h2 {
    font-size: 24px;
    font-weight: 600;
  
}
.filter-option {
  display: flex;
  flex-direction: column;
  margin: 10px;
}
.filter-option-title  h2{
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 5px;
  }


.filter-option-choices  select {
    width: 250px;
    border: none;
    border-bottom: 1px solid rgb(186, 191, 194);
    border-radius: 10px 10px 0 0;
    padding: 8px;
    background: #f5f8f9;
  }


.filter-modal-actions {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  
  
}
.filter-button {
    margin-right: 10px;
  }

@media screen and (max-width: 768px) {

  .filter-option-choices .select {
      width: 100%;
      border: none;
    }
}
</style>
