<template>
  <va-sidebar :minimized="minimized">
    <template slot="menu">
      <template v-for="(item, key) in items">
        <va-sidebar-link-group
          :key="key"
          :minimized="minimized"
          :icon="[ 'sidebar-menu-item-icon', item.meta.iconClass ]"
          v-if="item.children"
          :title="$t(item.displayName)"
          :children="item.children"
        >
          <template v-for="(subMenuItem, key) in item.children">
            <template v-if="subMenuItem.externalLink">
              <a
                :href="subMenuItem.externalLink"
                class="va-sidebar-link custom-sidebar-item"
                target="_blank"
                :key="key"
              >
                <!---->
                <div class="va-sidebar-link__content__title">{{$t(subMenuItem.displayName)}}</div>
              </a>
            </template>
            <template v-else>
              <va-sidebar-link
                :to="{ name: subMenuItem.name }"
                :title="$t(subMenuItem.displayName)"
                :key="key"
              />
            </template>
          </template>
        </va-sidebar-link-group>
        <va-sidebar-link
          v-else
          :key="key"
          :minimized="minimized"
          :activeByDefault="item.name === $route.name"
          :icon="[ 'sidebar-menu-item-icon', item.meta.iconClass ]"
          :to="item.target"
        >
          <span slot="title">{{ $t(item.displayName) }}</span>
        </va-sidebar-link>
      </template>
    </template>
  </va-sidebar>
</template>

<script>
import { navigationRoutes } from "./NavigationRoutes";
import hasGroup from "../../../utils/hasGroup";

export default {
  name: "app-sidebar",
  components: {},
  props: {
    minimized: {
      type: Boolean,
      required: true
    }
  },

  data() {
    return {
      items: navigationRoutes.routes,
      backofficeItems:{
        name: 'backoffice',
        displayName: 'Backoffice',
        meta: {
          iconClass: 'va-icon fa fa-wrench',
        },
        disabled: true,
        children: [
          {
            name: "backoffice",
            displayName: "Backoffice",
          },
          // TODO: Other backoffice menu items here
        ],
      },
    };
  },

  computed: {
    showBackoffice() {
      let _permissions= [];
      if (!this.$store.getters.customer.permissions) {
        _permissions = [];
      }
      else {
        _permissions = this.$store.getters.customer.permissions;
      }
      return _permissions.includes("backoffice") && 
        this.$route.meta.requiresBackofficeToken === true;
      
    }
  },

  watch: {
    showBackoffice(newVal){
      if (newVal === true && !this.items.some(item => item.name === 'backoffice')) {
        this.items.push(
          this.backofficeItems 
        )
        if (this.$store.getters.customer.permissions.includes("audit_logs")) {
          this.backofficeItems.children.push({
            name: "BoLogs",
            displayName: "Audit Logs",
          })
        }
      } 
      else if (newVal === false && this.items.some(item => item.name === 'backoffice')) {
        this.items = this.items.filter(item => item.name !== 'backoffice')
      }
    }
  
  },

  mounted() {
    if(hasGroup("fleet_enabled")){
        // Add fleet menu item to sidebar if user has fleet_enabled group
        
        this.items.splice(
            3, 0,
            {
                name: 'fleet',
                displayName: 'menu.fleet.title',
                target: "/fleets",
                meta: {
                    iconClass: 'va-icon fa fa-layer-group',
                },
            }
        )

    }

    if (this.showBackoffice) {
      this.items.push(this.backofficeItems)};
  },
};
</script>

<style>
.va-sidebar {
  background-color: #034860 !important;
}

.va-sidebar-link:hover {
  background-color: #1c5f75 !important;
}

.router-link-exact-active {
    background-color: #1c5f75 !important;
}

.va-sidebar 
div.va-sidebar__menu 
li.va-sidebar-link-group.va-sidebar-link-group--minimized 
div.va-dropdown 
div.va-dropdown__content 
div.va-sidebar-link-group__submenu.in{
        background-color: #034860 !important;
}
</style>
