<template>
  <div
    class="icon"
    v-if="isSvg"
    v-html="getIconHtml()"
    :style="{
      ...colors[color],
      height: scaledHeight + 'px',
      width: scaledWidth + 'px',
      cursor: cursor,
      opacity: disabled ? 0.2 : 1,
    }"
    @click="handleClick"
    @mouseover="handleMouseOver"
    @mouseleave="handleMouseLeave"
    :title="tooltip"></div>
  <img
    v-else
    :src="iconSrc"
    @click="handleClick"
    @mouseover="handleMouseOver"
    @mouseleave="handleMouseLeave"
    :title="tooltip" />
</template>

<script>
import icons from "../media/icons/icons.js";
import defaultIcon from "../media/icons/default.svg";

export default {
  name: "SixIcon",

  props: {
    name: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      default: "",
    },
    tooltip: {
      type: String,
      default: "",
    },
    height: {
      type: Number,
      default: 24,
    },
    width: {
      type: Number,
      default: 24,
    },
    scale: {
      type: Number,
      default: 1,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    clickable: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      hover: false,
      colors: {
        white: "#ffffff",
        grey: "var(--color-deactive-text)",
        primary: "var(--color-primary-solid)",
        secondary: "var(--color-secondary)",
        danger: "var(--color-danger)",
        success: "var(--color-success)",
        billingBlue: "var(--color-billing-blue)",
        billingGreen: "var(--color-billing-green)",
        darkGrey: "#C2C2C2",
        // We will add more colors when needed
      },
      icons: icons,
    };
  },

  computed: {
    scaledHeight() {
      return this.height * this.scale;
    },
    scaledWidth() {
      return this.width * this.scale;
    },
    iconSrc() {
      const iconName =
        this.hover && this.icons[`${this.name}-hover`]
          ? `${this.name}-hover`
          : this.name;
      const supportedFormats = [".svg", ".jpg", ".png", ".jpeg", ".webp"];
      const imagePath = supportedFormats.find((format) => {
        try {
          require(`../media/icons/${iconName}${format}`);
          return true;
        } catch (error) {
          return false;
        }
      });

      if (!imagePath) {
        return defaultIcon;
      }

      return require(`../media/icons/${iconName}${imagePath}`);
    },
    isSvg() {
      const iconName =
        this.hover && this.icons[`${this.name}-hover`]
          ? `${this.name}-hover`
          : this.name;
      return this.icons[iconName] !== undefined;
    },
    cursor() {
      if (this.disabled) {
        return "not-allowed";
      } else if (this.clickable) {
        return "pointer";
      } else {
        return "default";
      }
    },
  },

  methods: {
    handleClick() {
      if (this.disabled) return;
      this.$emit("click");
    },
    handleMouseOver() {
      if (!this.disabled && this.icons[`${this.name}-hover`]) {
        this.hover = true;
      }
    },
    handleMouseLeave() {
      this.hover = false;
    },
    getIconHtml() {
      const iconName =
        this.hover && this.icons[`${this.name}-hover`]
          ? `${this.name}-hover`
          : this.name;
      const iconHtml = this.icons[iconName];
      if (!iconHtml) return "";

      let coloredIconHtml = iconHtml;

      if (this.colors[this.color]) {
        const colorStyle = `fill: ${this.colors[this.color]}`;
        coloredIconHtml = coloredIconHtml.replace(
          /<path/g,
          `<path style="${colorStyle}"`
        );
      }

      return coloredIconHtml.replace(
        /<svg/g,
        `<svg height="${this.scaledHeight}" width="${this.scaledWidth}"`
      );
    },
  },
};
</script>
