import {mapGetters} from "vuex";
import {hasPermission} from "../utils/permissions";

export default {
  computed: {
    ...mapGetters(["customer"]),
  },
  methods: {
    hasPermission(resourceAction) {
      return hasPermission(resourceAction, this.customer);
    },
  },
};
