var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{
        'sf-card': true,
        'sf-card__padding': !_vm.noPadding,
        'full-height': _vm.fullHeight,
    }},[(_vm.loading)?_c('div',{staticClass:"sf-card__loading-hover"},[_c('sf-icons',{attrs:{"scale":"3","spin":"","name":"spinner"}}),_vm._v(" "+_vm._s(_vm.loadingText)+" ")],1):_vm._e(),(_vm.beta)?_c('beta-badge'):_vm._e(),_c('div',{class:{
            'sf-card__container': true,
            'sf-card__blur': _vm.loading,
        }},[(_vm.title)?_c('div',{staticClass:"va-row row wrap"},[_c('div',{staticClass:"flex xs12 xl6"},[(_vm.title)?_c('header',[_vm._v(_vm._s(_vm.title))]):_vm._e()]),_c('div',{staticClass:"flex xs12 xl6 text-right"},[_vm._t("actions")],2)]):_vm._e(),_vm._t("default")],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }