<template>
  <va-card class="profile-card">
    <h1 class="section-header">Customer Details</h1>
    <p class="subtitle">Details of customer</p>
    <div class="profile-info">
      <div class="circle-avatar">
        <img :src="customer.gravatar_url" class="avatar-image">
      </div>

      <h1>{{ customer.email }}</h1>

      <div v-for="details in customer_details" :key="details.id" class="flex row padding-05-rem margin-y-05-rem">
        <h2 class="flex  md12 text-left padding-02-rem">{{ details.title }}</h2>
        <span class="flex md12 text-left padding-02-rem " style="white-space: pre-line;">{{ customer[details.key] }}</span>
      </div>

    </div>
  </va-card>
</template>
<script>

export default {
  name: "sf-profile-card",

  data() {
    return {
      customer_details: [
        {
          id: 1,
          title: "Customer ID",
          value: "",
          key: "id",
        },
        {
          id: 2,
          title: "Stripe ID",
          value: "",
          key: "stripe_customer_id",
        },
        {
          id: 3,
          title: "Auto Renew Amount",
          value: "",
          key: "auto_renew_amount",
        },
        {
          id: 4,
          title: "Minimum Credit Limit",
          value: "",
          key: "minimum_credit_limit",
        },
        {
          id: 5,
          title: "Balance",
          value: "",
          key: "balance",
        },
        {
          id: 6,
          title: "Total asset count",
          value: "",
          key: "asset_count",
        },
        {
          id:7,
          title: "Assets by type",
          value: "",
          key:"asset_types"
        }
      ]
    };
  },
  props: {
    customer: {
      type: Object,
      required: true,
    },
  },
  computed: {
    customerAvatar() {
      return `https://ui-avatars.com/api/?name=${this.customer._email}`;
    },
  },


};
</script>

<style lang="scss" scoped>

.profile-card {
  min-height: 692px;
}

.section-header {
  font-size: 1.5rem;
  font-weight: bold;
}

.subtitle {
  font-size: 1rem;
  opacity: 0.5;
}

.avatar-image {
  max-width: 32%;
  border-radius: 80%;
  object-fit: cover;
}

.circle-avatar {
  min-height: 120px;
}

.profile-info {
  text-align: center;
  margin-bottom: 20px;
}

.padding-02-rem {
  padding: 0.1rem !important
}
.padding-05-rem{
  padding: 0.5rem !important
}
.margin-y-05-rem{
  // top and bottom
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}
</style>