import sixBanner from "./components/sixBanner.vue";
import sixNavbar from "./components/sixNavbar.vue";
import sixSidebar from "./components/sixSidebar.vue";
import sixIcon from "./components/sixIcon.vue";
import sixTable from "./components/sixTable.vue";
import sixButton from "./components/sixButton.vue";
import sixCard from "./components/sixCard.vue";
import sixFooter from "./components/sixFooter.vue";
import sixSelect from "./components/sixSelect.vue";
import sixModal from "./components/sixModal.vue";
import sixProgressBar from "./components/sixProgressBar.vue";
import sixSwitch from "./components/sixSwitch.vue";
import sixChart from "./components/sixChart.vue";
import sixLatestActivity from "./components/sixLatestActivity.vue";
import sixDoughnutChart from "./components/sixDoughnutChart.vue";
import sixChip from "./components/sixChip.vue";
import sixDateInput from "./components/sixDateInput.vue";
import sixInput from "./components/sixInput.vue";
import sixSlider from "./components/sixSlider.vue";
import sixMap from "./components/sixMap.vue";
import sixToast from "./components/sixToast.vue";
import sixAccordion from "./components/sixAccordion.vue";
import sixCreditCard from "./components/sixCreditCard.vue";
import sixPopover from "./components/sixPopover.vue";
import sixCollapsible from "./components/sixCollapsible.vue";

const SixUI = {
  install(Vue) {
    Vue.component("six-banner", sixBanner);
    Vue.component("six-navbar", sixNavbar);
    Vue.component("six-sidebar", sixSidebar);
    Vue.component("six-icon", sixIcon);
    Vue.component("six-table", sixTable);
    Vue.component("six-button", sixButton);
    Vue.component("six-card", sixCard);
    Vue.component("six-footer", sixFooter);
    Vue.component("six-select", sixSelect);
    Vue.component("six-modal", sixModal);
    Vue.component("six-progress-bar", sixProgressBar);
    Vue.component("six-switch", sixSwitch);
    Vue.component("six-chart", sixChart);
    Vue.component("six-latest-activity", sixLatestActivity);
    Vue.component("six-doughnut-chart", sixDoughnutChart);
    Vue.component("six-chip", sixChip);
    Vue.component("six-date-input", sixDateInput);
    Vue.component("six-input", sixInput);
    Vue.component("six-slider", sixSlider);
    Vue.component("six-map", sixMap);
    Vue.component("six-toast", sixToast);

    Vue.prototype.sixToast = function (toast) {
      this.$root.$emit("addToast", toast);
    };
    Vue.component("six-accordion", sixAccordion);
    Vue.component("six-credit-card", sixCreditCard);

    Vue.component("six-popover", sixPopover);
    Vue.component("six-collapsible", sixCollapsible);
  },
};

export default SixUI;
