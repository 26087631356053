<template>
    <div>
        <table
            :class="{
                'first-row-bold': firstRowBold && currentPage === 1,
            }"
        >
            <thead>
                <tr class="header">
                    <th
                        scope="col"
                        v-for="field in fields"
                        v-bind:key="field.key"
                        @click="sortField(field)"
                    >
                        <sf-icons
                            v-if="field.sortable && field.title !== '' && field.key === sortedField"
                            class="ml-1"
                            scale=".8"
                            :name="sortDirection === 1 ? 'sort-a-z' : 'sort-z-a'"
                        />
                        {{ field.title }}
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr
                    v-for="row in pageData"
                    v-bind:key="JSON.stringify(row)"
                    @click="rowClicked(row)"
                    :class="{
                        'hoverable': hoverable,
                    }"
                >
                    <td
                        v-for="field in fields"
                        v-bind:key="field.key"
                        :data-label="field.title"
                        :style="{ textAlign: field.textAlign }"
                    >
                        <slot :name="field.key" :content="row">
                            {{ row[field.key] }}
                        </slot>
                    </td>
                </tr>
            </tbody>
        </table>

        <div class="pagination" v-if="!loading && pageCount > 1">
            <template v-for="step in paginationSchema(currentPage, pageCount)">
                <span
                    v-bind:key="step"
                    v-if="step === 'button-prev'"
                    class="button"
                    @click="goToPage(currentPage - 1)"
                >
                    &lt;
                </span>
                <span
                    v-bind:key="step"
                    v-else-if="step === 'button-next'"
                    class="button"
                    @click="goToPage(currentPage + 1)"
                >
                    &gt;
                </span>
                <span
                    v-bind:key="step"
                    v-else-if="typeof step === 'number'"
                    :class="{
                        page: true,
                        active: step === currentPage,
                    }"
                    @click="goToPage(step)"
                >
                    {{ step }}
                </span>
                <span
                    v-bind:key="step + Math.floor(Math.random() * 100)"
                    v-else-if="step === 'spacer'"
                    class="spacer"
                >
                    ...
                </span>
            </template>
        </div>
    </div>
</template>

<script>
export default {
    name: "sf-table",
    props: {
        data: {
            type: Array,
            required: true,
            // schema:
            //  [
            //     {
            //         date: "foo",
            //         download: "bar",
            //         upload: "alpha",
            //         table__style: "color: red;" <-- this is a custom style of row, optional
            //     },
            //  ]
        },

        fields: {
            type: Array,
            required: true,
            // schema:
            //  [
            //     {
            //         title: "FooBar",
            //         key: "[data.]title",
            //         sortable: true|false|optional,
            //         style: "customStyleString|optional"
            //         textAlign: center|left|right| optional;
            //     }
            //  ]
            validator: (fields) => {
                return fields.every((field) => {
                    return (
                        (field.textAlign === "center" ||
                            field.textAlign === "left" ||
                            field.textAlign === "right" ||
                            field.textAlign === undefined)
                        &&
                        (
                            field.sortable === true ||
                            field.sortable === false ||
                            field.sortable === undefined
                        )
                    );
                });
            },
        },

        firstRowBold: {
            type: Boolean,
            default: false,
        },

        pageLimit: {
            type: Number,
            default: 25,
        },

        loading: {
            type: Boolean,
            default: false,
        },

        hoverable: {
            type: Boolean,
            default: false,
        },
        page_count: {
          type: Number,
          default: 0,
        },


    },

    data() {
        return {
            columns: [],
            currentPage: 1,
            sortedField: "",
            sortDirection: 1,
        };
    },

    computed: {
        pageData() {
          // if the prop "page_count" is set, use it data since it is already paginated
          if (this.page_count != 0) {
            return this.data;
          }
            let offsetPage = this.currentPage - 1;
            return this.data.slice(
                offsetPage * this.pageLimit,
                (offsetPage + 1) * this.pageLimit
            );
        },

        pageCount() {
          // if the prop "page_count" is set, use it instead of calculating it
          if (this.page_count != 0) {
            return this.page_count;
          }
            return parseInt(this.data.length / this.pageLimit) + 1;
        },

        paginationSchema() {
            return (currentPage, maximumPage) => {
                var current = (currentPage += 1),
                    last = maximumPage,
                    delta = 2,
                    left = current - delta,
                    right = current + delta + 1,
                    range = [],
                    rangeWithDots = [],
                    l;

                for (let i = 1; i <= last; i++) {
                    if (i == 1 || i == last || (i >= left && i < right)) {
                        range.push(i);
                    }
                }

                for (let i of range) {
                    if (l) {
                        if (i - l === 2) {
                            rangeWithDots.push(l + 1);
                        } else if (i - l !== 1) {
                            rangeWithDots.push("spacer");
                        }
                    }
                    rangeWithDots.push(i);
                    l = i;
                }

                if (rangeWithDots.length > 1) {
                    rangeWithDots.unshift("button-prev");
                    rangeWithDots.push("button-next");
                }

                return rangeWithDots;
            };
        },
    },

    methods: {
        goToPage(page) {
            if (page > this.pageCount) {
                return this.pageCount;
            } else if (page < 1) {
                return 1;
            } else {
                this.sortedField = ""
                this.currentPage = page;
                this.$emit("pageChanged", this.currentPage);
            }
        },

        rowClicked(row) {
            this.$emit("row-clicked", row);
        },

        sortField(field) {
            if (field.sortable) {
                console.log("sort field", field)
                this.sortedField = field.key;
                this.sortDirection = this.sortDirection * -1;

                this.data.sort((a, b) => {
                const numA = parseFloat(a[field.key]);
                const numB = parseFloat(b[field.key]);

                if (!isNaN(numA) && !isNaN(numB)) {
                    return (numA - numB) * this.sortDirection;
                } else if (
                    typeof a[field.key] === "boolean" &&
                    typeof b[field.key] === "boolean"
                ) {
                    return (a[field.key] - b[field.key]) * this.sortDirection;
                } else {
                    return a[field.key].localeCompare(b[field.key]) * this.sortDirection;
                }
                });
            }
    },
    },
};
</script>

<style lang="scss" scoped>
table {
    margin: 0;
    padding: 0;
    width: 100%;
    table-layout: fixed;
}

table .header {
    border-bottom: 1px solid #ccc;
    user-select: none;
}

table .asset:nth-child(2n) {
    background-color: #f5f5f5;
}

table caption {
    font-size: 1.5em;
    margin: 0.5em 0 0.75em;
    text-align: left;
    clear: color;

    & .text {
        font-weight: bold;
        float: left;
        width: 50%;
    }
    & .actions {
        width: 50%;
        float: left;
        text-align: right;
        margin-top: -0.5em;
    }
}

table tr {
    padding: 0.35em;

    &.bold {
        font-weight: bold !important;
    }
}

table tbody tr:nth-child(even) {
    background-color: #f5f8f9;
}

table tbody tr.hoverable:hover {
    background-color: #dce7ea;
    cursor: pointer;
}

table th,
table td {
    padding: 0.625em;
    text-align: center;
    position: relative;
}

table th {
    font-size: 0.85em;
    letter-spacing: 0.1em;
    text-transform: uppercase;
}

@media screen and (max-width: 600px) {
    table {
        border: 0;
    }

    table caption {
        font-size: 1.3em;
    }

    table thead {
        border: none;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
    }

    table tr {
        display: block;
        margin-bottom: 0.625em;
    }

    table td {
        display: block;
        font-size: 0.8em;
        text-align: right;
    }

    table td::before {
        /*
    * aria-label has no advantage, it won't be read inside a table
    content: attr(aria-label);
    */
        content: attr(data-label);
        float: left;
        font-weight: bold;
        text-transform: uppercase;
    }

    table td:last-child {
        border-bottom: 0;
    }
}

.first-row-bold {
    & tbody tr:first-child {
        font-weight: bold;
    }
}


.pagination {
    display: flex;
    justify-content: center;
    font-size: 12px;
    user-select: none;
    font-family: "Haas Grot Text R Web", "Helvetica Neue", Helvetica, Arial,
        sans-serif;
    margin-top: 1rem;

    & .button {
        display: inline;
        padding: 0.2rem 0.6rem;
        margin: 0 10px;
        cursor: pointer;
        border-radius: 99px;
        font-weight: 8;
    }

    & .page {
        padding: 0.2rem 0.6rem;
        border-radius: 99px;
        cursor: pointer;
    }

    & .page:hover {
        background-color: rgba(51, 51, 51, 0.1);
    }

    & .page.active {
        background-color: rgba(51, 51, 51, 0.2);
        font-weight: 800;
    }

    & .spacer {
        flex-grow: 0 !important;
        margin: 0 10px;
    }
}
</style>
