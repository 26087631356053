var render = function render(){var _vm=this,_c=_vm._self._c;return _c('transition',{attrs:{"name":"slide"}},[(_vm.isOpen || !_vm.fullWidth)?_c('div',{class:{
      'sidebar': true,
      'sidebar-closed': !_vm.isOpen,
    },style:({width: _vm.fullWidth ? '100%' : ''})},_vm._l((_vm.filteredMenu),function(group,index){return _c('div',{key:index,staticClass:"group",style:({width: _vm.fullWidth ? '100%' : ''})},[_c('transition',{attrs:{"name":"slide-title"}},[_c('h6',{directives:[{name:"show",rawName:"v-show",value:(_vm.isOpen),expression:"isOpen"}],staticClass:"group-title"},[_vm._v(" "+_vm._s(group.title)+" ")])]),_vm._l((group.items),function(item){return _c('div',{key:item.name,style:({width: _vm.fullWidth ? '100%' : ''})},[(!item.children)?_c('div',{class:{
            'active-item': item.name == _vm.activeItem,
            'group-item': true,
          },on:{"click":function($event){return _vm.toggleActive(item.name, item.link)}}},[_c('six-icon',{staticClass:"item-icon",attrs:{"name":item.icon,"color":_vm.activeItem === item.name ? 'white' : '',"tooltip":!_vm.isOpen ? item.name : '',"clickable":""}}),(_vm.isOpen)?_c('h6',{class:{
              'item-name': true,
            }},[_vm._v(" "+_vm._s(item.name)+" ")]):_vm._e()],1):_c('div',{class:{
            'active-item': item.children.some(
              (child) => child.name == _vm.activeItem
            ),
            'group-item': true,
          },on:{"click":function($event){return _vm.toggleChildren(item)}}},[_c('six-icon',{staticClass:"item-icon",attrs:{"color":item.children.some((child) => child.name == _vm.activeItem)
                ? 'white'
                : '',"name":item.icon,"tooltip":!_vm.isOpen ? item.name : '',"clickable":""}}),(_vm.isOpen)?_c('h6',{staticClass:"item-name"},[_vm._v(" "+_vm._s(item.name)+" ")]):_vm._e(),(_vm.isOpen)?_c('six-icon',{staticClass:"item-toggle-icon",style:({
              transform: _vm.openItem.name == item.name ? 'rotate(90deg)' : '',
            }),attrs:{"color":item.children.some((child) => child.name == _vm.activeItem)
                ? 'white'
                : '',"name":"rightArrow"}}):_vm._e()],1),(_vm.openItem.name == item.name)?_c('div',{class:{
            'children-dropdown': !_vm.isOpen,
          }},_vm._l((item.children),function(child){return _c('div',{key:child.name,class:{
              'active-item': child.name == _vm.activeItem,
              'child-item': true,
            },on:{"click":function($event){return _vm.toggleActive(child.name, child.link)}}},[_c('six-icon',{staticClass:"child-item-icon",attrs:{"name":child.icon,"color":_vm.activeItem === child.name ? 'white' : ''}}),_c('h6',{staticClass:"child-name"},[_vm._v(_vm._s(child.name))])],1)}),0):_vm._e()])})],2)}),0):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }