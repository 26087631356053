import { render, staticRenderFns } from "./sixBanner.vue?vue&type=template&id=dae85026&scoped=true&"
import script from "./sixBanner.vue?vue&type=script&lang=js&"
export * from "./sixBanner.vue?vue&type=script&lang=js&"
import style0 from "./sixBanner.vue?vue&type=style&index=0&id=dae85026&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dae85026",
  null
  
)

export default component.exports