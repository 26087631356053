<template>
  <div
    @mouseenter="showPopover"
    @mouseleave="hidePopover"
    @click.stop="togglePopover">
    <div
      v-if="isPopoverVisible"
      :style="computedPopoverStyle"
      class="popover">
      <div class="popover-content">
        <slot name="popover"></slot>
      </div>
    </div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "SixPopover",

  props: {
    trigger: {
      type: String,
      default: "hover",
      validator: (value) => ["hover", "click"].includes(value),
    },

    placement: {
      type: String,
      default: "top",
      validator: (value) => ["top", "bottom", "left", "right"].includes(value),
    },
  },

  data() {
    return {
      isPopoverVisible: false,
    };
  },

  computed: {
    computedPopoverStyle() {
      const style = {
        display: this.isPopoverVisible ? "block" : "none",
      };

      switch (this.placement) {
        case "top":
          style.bottom = "100%";
          style.left = "50%";
          style.transform = "translateX(-50%)";
          break;
        case "bottom":
          style.top = "100%";
          style.left = "50%";
          style.transform = "translateX(-50%)";
          break;
        case "left":
          style.right = "100%";
          style.top = "50%";
          style.transform = "translateY(-50%)";
          break;
        case "right":
          style.left = "100%";
          style.top = "50%";
          style.transform = "translateY(-50%)";
          break;
        default:
          break;
      }

      return style;
    },
  },

  methods: {
    showPopover() {
      if (this.trigger === "hover") {
        this.isPopoverVisible = true;
      }
    },

    hidePopover() {
      if (this.trigger === "hover") {
        this.isPopoverVisible = false;
      }
    },

    togglePopover() {
      if (this.trigger === "click") {
        this.isPopoverVisible = !this.isPopoverVisible;
      }
    },
  },
};
</script>

<style scoped>
.popover {
  position: absolute;
  background-color: white;
  color: black;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0px 5px 12px -2px rgba(27, 33, 44, 0.12);
  z-index: 99999;
}

.popover-content {
  display: flex;
  flex-direction: column;

  color: #000;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 12px;
}
</style>
