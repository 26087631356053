<template>
  <div class="collapsible">
    <div class="collapsible-group">
      <div class="collapsible-header">
        <slot name="header"></slot>
      </div>
      <div
        class="arrow-group"
        @click="toggle">
        <span
          class="arrow"
          :class="{open: isOpen}"
          >&#9662;
        </span>
      </div>
    </div>
    <transition name="slide-fade">
      <div
        v-show="isOpen"
        class="collapsible-body">
        <slot></slot>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "SixCollapsible",
  data() {
    return {
      isOpen: false,
    };
  },
  methods: {
    toggle() {
      this.isOpen = !this.isOpen;
    },
  },
};
</script>

<style scoped>
.collapsible {
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-bottom: 10px;
}
.collapsible-group {
  cursor: pointer;
  padding: 10px;
  background-color: #f9f9f9;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.collapsible-header {
  display: flex;
  gap: 10px;
}
.collapsible-body {
  padding: 10px;
}
.arrow {
  transition: transform 0.2s;
}
.arrow.open {
  transform: rotate(180deg);
}
.arrow-group {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}
.slide-fade-enter-active {
  transition: all 0.2s ease;
}
.slide-fade-leave-active {
  transition: all 0.1s ease;
}
.slide-fade-enter,
.slide-fade-leave-to {
  opacity: 0;
  transform: translateY(-5px);
}
</style>
