var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('table',{class:{
            'first-row-bold': _vm.firstRowBold && _vm.currentPage === 1,
        }},[_c('thead',[_c('tr',{staticClass:"header"},_vm._l((_vm.fields),function(field){return _c('th',{key:field.key,attrs:{"scope":"col"},on:{"click":function($event){return _vm.sortField(field)}}},[(field.sortable && field.title !== '' && field.key === _vm.sortedField)?_c('sf-icons',{staticClass:"ml-1",attrs:{"scale":".8","name":_vm.sortDirection === 1 ? 'sort-a-z' : 'sort-z-a'}}):_vm._e(),_vm._v(" "+_vm._s(field.title)+" ")],1)}),0)]),_c('tbody',_vm._l((_vm.pageData),function(row){return _c('tr',{key:JSON.stringify(row),class:{
                    'hoverable': _vm.hoverable,
                },on:{"click":function($event){return _vm.rowClicked(row)}}},_vm._l((_vm.fields),function(field){return _c('td',{key:field.key,style:({ textAlign: field.textAlign }),attrs:{"data-label":field.title}},[_vm._t(field.key,function(){return [_vm._v(" "+_vm._s(row[field.key])+" ")]},{"content":row})],2)}),0)}),0)]),(!_vm.loading && _vm.pageCount > 1)?_c('div',{staticClass:"pagination"},[_vm._l((_vm.paginationSchema(_vm.currentPage, _vm.pageCount)),function(step){return [(step === 'button-prev')?_c('span',{key:step,staticClass:"button",on:{"click":function($event){return _vm.goToPage(_vm.currentPage - 1)}}},[_vm._v(" < ")]):(step === 'button-next')?_c('span',{key:step,staticClass:"button",on:{"click":function($event){return _vm.goToPage(_vm.currentPage + 1)}}},[_vm._v(" > ")]):(typeof step === 'number')?_c('span',{key:step,class:{
                    page: true,
                    active: step === _vm.currentPage,
                },on:{"click":function($event){return _vm.goToPage(step)}}},[_vm._v(" "+_vm._s(step)+" ")]):(step === 'spacer')?_c('span',{key:step + Math.floor(Math.random() * 100),staticClass:"spacer"},[_vm._v(" ... ")]):_vm._e()]})],2):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }