<template>
  <div>
    <div class="search-container">
      <input
        class="search-input"
        type="text"
        :placeholder="placeholder"
        v-model="searchQuery"
        @input="handleInput"
      />
      <sf-button class="search-button" @click.native="handleClick"
        >Search</sf-button
      >
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import SfButton from "./SfButton.vue";
export default {
  components: { SfButton },
  props: {
    placeholder: {
      type: String,
      default: "Search...",
    },
  },
  data() {
    return {
      searchQuery: "",
    };
  },

  created() {
    this.searchQuery = this.$store.getters.searchQuery;
  },

  mounted() {
    document.addEventListener("keydown", (e) => {
      if (e.key === "Enter") {
        this.handleClick();
      }
    });
  },
  methods: {
    handleInput() {
      this.$emit("input", {
        query: this.searchQuery,
      });
    },
    handleClick() {
      this.$emit("search");
    },
  },
};
</script>

<style scoped lang="scss">
.search-container {
  margin-top: 3px;
  display: flex;
  width: 100%;
}
input[type="text"] {
  border: none;
  border-radius: 8px 0 0 8px;
  font-size: 1rem;
  background-color: rgba(51, 51, 51, 0.05);
  padding: 0.5rem 0.75rem;
  &::placeholder {
    color: #8f8f8f;
  }
  height: inherit;
}
.search-button {
  cursor: pointer;
  border-radius: 0 8px 8px 0;
  background-color: rgba(51, 51, 51, 0.05);
}
@media (max-width: 768px) {
  input[type="text"] {
    font-size: 0.7rem;
  }
  .search-button {
    font-size: 0.7rem;
  }
}
</style>
