<template>
  <div class="container">
    <div class="header">
      <div class="card-type">
        <h3 class="bold">{{ cardInfo.brand.toUpperCase() || "CARD" }}</h3>
        <!-- <div style="font-size: 13px; font-weight: 300">PREMIUM ACCOUNT</div> -->
      </div>
      <!-- <div class="card-logo">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="116"
          height="71"
          viewBox="0 0 116 71"
          fill="none"
        >
          <circle
            opacity="0.25"
            cx="35.4998"
            cy="35.501"
            r="35.5"
            fill="white"
          />
          <circle
            opacity="0.5"
            cx="79.5197"
            cy="35.501"
            r="35.5"
            fill="white"
          />
        </svg>
      </div> -->
    </div>

    <div class="card-number uih4 medium">
      <span>****</span>
      <span>****</span>
      <span>****</span>
      <span>{{ cardInfo.lastFour || "****" }}</span>
    </div>

    <div class="card-info">
      <!-- <div class="holder">
        <div style="font-size: 11px; font-weight: 300">Card Holder</div>
        <div>{{ cardInfo.cardHolder }}</div>
      </div> -->
      <div class="expire">
        <div class="xs-text">Expire Date</div>
        <h5 class="medium">{{ cardInfo.expiration || "MM/YY" }}</h5>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SixCreditCard",
  props: {
    cardInfo: {
      type: Object,
      default: () => ({
        brand: "VISA",
        lastFour: "2847",
        expiration: "12/24",
      }),
    },
  },
};
</script>

<style scoped>
* {
  color: white !important;
}

.container {
  display: inline-flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
  border-radius: 24px;
  border: 2px solid #fff;
  background: rgba(255, 255, 255, 0.25);
  backdrop-filter: blur(32px);
}

.header {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.card-type {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.card-number {
  display: flex;
  gap: 40px;
}

.card-info {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 4px;
}

@media screen and (max-width: 1440px) {
  .container {
    padding: 16px;
    width: 100%;
    padding-right: 0;
  }
}
</style>
