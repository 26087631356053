<template>
  <div class="toast-notification">
    <div class="header">
      <six-icon :name="toastIcon" />
      <div
        class="title"
        :class="type">
        <div>
          {{ title }}
        </div>
      </div>
      <six-icon
        name="closeSquare"
        @click="onClose"
        clickable
        :style="{marginLeft: 'auto'}" />
    </div>
    <div class="content">
      <slot
        class="message"
        name="message" />
    </div>
  </div>
</template>

<script>
import SixIcon from "../components/sixIcon.vue";
export default {
  name: "SixToast",

  components: {
    SixIcon,
  },

  props: {
    title: {
      type: String,
      default: "",
    },

    type: {
      type: String,
      default: "info",
    },

    timeout: {
      type: Number,
      default: 5000,
    },
  },

  computed: {
    toastIcon() {
      if (this.type === "success") {
        return "checkCircle";
      } else if (this.type === "info") {
        return "infoCircle";
      } else if (this.type === "error") {
        return "errorCircle";
      }
    },
  },

  methods: {
    onClose() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

.toast-notification {
  background: #fff;
  margin: 10px;
  border-radius: 4px;
  min-width: 340px;
  max-width: 500px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  font-family: "Inter";
}

.header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid var(--color-deactive);
  padding: 8px 16px;
  gap: 4px;
}

.title {
  font-family: "Inter";
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  display: flex;
  align-items: center;
  white-space: nowrap;
}

.content {
  padding: 16px;

  color: var(--color-deactive-text);

  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;

  word-wrap: break-word;
  white-space: normal;
}

.success {
  color: var(--color-success);
}
.info {
  color: #dca500;
}
.error {
  color: var(--color-danger);
}
</style>
