<template>
  <div :class="{
    'assets-button': true,
    'border': border,
    'small': small,
    'color__default': color === 'default',
    'color__red': color === 'red',
    'color__green': color === 'green',
    'color__blue': color === 'blue',
    'color__dark-gray': color === 'dark-gray',
    'color__cream': color === 'cream',
    'disabled': disabled
  }"
    @click="clickEvent">
    <slot></slot>
  </div>
</template>

<script>
export default {
    name: "sf-button",
    props: {
        border: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        small: {
            type: Boolean,
            default: false
        },
        color: {
            type: String,
            default: 'default'
        },
    },

    methods: {
        clickEvent() {
            if (!this.disabled) {
                this.$emit('click');
            }
        }
    }
}
</script>

<style scoped lang="scss">
.assets-button {
    border-radius: 8px;

    cursor: pointer;
    display: inline-block;
    font-family: "Haas Grot Text R Web", "Helvetica Neue", Helvetica, Arial,
        sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    list-style: none;
    margin: 0;
    padding: 10px 12px;
    text-align: center;
    transition: all 200ms;
    vertical-align: baseline;
    white-space: nowrap;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
}

.color {
    &__default {
        background-color: rgba(51, 51, 51, 0.05);
        color: #333333;
    }

    &__dark-gray {
        background-color: rgba(51, 51, 51, 0.1);
        color: #333;
    }

    &__red {
        background-color: rgba(255, 105, 97, 1);
        color: #fff;
    }

    &__green {
        background-color: rgba(119, 221, 119, 1);
        color: #fff;
    }

    &__blue {
        background-color: rgba(66, 156, 191, 1);
        color: #fff;
    }

    &__cream {
        background-color: rgba(233, 249, 254, 1);
        color: #333;
    }

    &__transparent {
        background-color: transparent;
        color: #333;
    }
}

.border {
    border-width: .6px;
    border-color: rgba(51, 51, 51, 0.2);;
    border-style: solid;
}

.small {
    padding: 5px 6px;
    font-size: 12px;
}
.disabled {
    opacity: 0.5;
    cursor: not-allowed;
    // This may be need to be double checked
    pointer-events: none;
}
</style>