<template>
  <div class="slider">
    <div class="slider-label">{{ selectedOption.label }}</div>
    <input
      class="slider-input"
      type="range"
      :min="0"
      :max="options.length - 1"
      @change="handleSliderChange"
      @input="handleSliderChange"
      v-model="selectedIndex"
    />

  </div>
</template>

<script>
export default {
  props: {
    options: {
      type: Array,
      required: true,
    },
    defaultOption: {
      type: Number,
      default: 0,
    },
  },
  created() {
    this.selectedIndex = this.defaultOption;
  },
  data() {
    return {
      selectedIndex: 0,
    };
  },
  computed: {
    selectedOption() {
      return this.options[this.selectedIndex];
    },
  },
  methods: {
    handleSliderChange() {
      this.$emit("change", this.selectedOption.value);
    },
  },
};
</script>

<style>
.slider {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1rem;
  margin-top: 1rem;
  border:#e0e0e0 1px solid;
  border-radius: 0.5rem;
  background-color: #ffffff;
  padding: .5rem;

}
.slider-input{
  width: 100%;
  height: 1rem;
  background-color: #f5f5f5;
  border-radius: 0.5rem;
  border:1px solid #e0e0e0;
  -webkit-appearance: none;
}

 .slider-input::-webkit-slider-thumb{
  -webkit-appearance: none;
  appearance: none;
  width: 1.5rem;
  height: 1.5rem;
  background-color:  rgba(95, 175, 95, 0.897);
  box-shadow: 0px 0px 20px rgba(109, 105, 105, 0.75);
  border-radius: 50%;
  cursor: pointer;
}

 .slider-input::-moz-range-thumb {
  width: 2rem;
  height: 2rem;
  background-color:  rgb(95, 175, 95);
  border-radius: 50%;
  cursor: pointer;
}

.slider-label {
  margin-top: 0;
  font-size: 1.25rem;
  font-weight: bold;
  padding: 0.5rem;
  margin-bottom: .5rem;  
}
</style>
