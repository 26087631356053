<template>
  <div class="progress-container">
    <slot
      v-if="!innerLabel"
      name="label">
    </slot>
    <div
      class="progress-bar"
      :style="{
        height: sizeAsPx,
        background: bgColor,
      }">
      <div
        :class="['progress-bar-inner', {indeterminate}]"
        :style="{
          width: `${percentage}%`,
          background: progressColor,
        }">
        <slot
          v-if="innerLabel"
          name="label">
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SixProgressBar",

  props: {
    value: {
      type: Number,
      required: true,
    },

    max: {
      type: Number,
      default: 100,
    },

    indeterminate: {
      type: Boolean,
      default: false,
    },

    size: {
      type: String,
      default: "medium",
      validator: function (value) {
        return ["small", "medium", "large"].includes(value);
      },
    },

    color: {
      // Could be a hex color or a predefined color
      type: String,
      default: "primary",
      validator: function (value) {
        return (
          value.startsWith("#") ||
          [
            "primary",
            "secondary",
            "success",
            "danger",
            "billingBlue",
            "billingGreen",
            "lightBlue",
          ].includes(value)
        );
      },
    },

    bgGradient: {
      // Whether to show the gradient background or not
      type: Boolean,
      default: true,
    },

    innerLabel: {
      // Whether to show the percentage inside the bar or not
      type: Boolean,
      default: false,
    },
  },

  computed: {
    percentage() {
      return (this.value / this.max) * 100;
    },

    progressColor() {
      const colors = {
        primary: "var(--color-primary-gradient)",
        secondary: "var(--color-secondary)",
        success: "var(--color-success)",
        danger: "var(--color-danger)",
        billingBlue: "var(--color-billing-blue)",
        billingGreen: "var(--color-billing-green)",
        lightBlue: "var(--color-light-blue)",
      };

      return colors[this.color] || this.color;
    },

    bgColor() {
      if (this.bgGradient) {
        return "linear-gradient(to bottom right, #d7d9db, #e9ecef)";
      } else {
        return "rgba(33, 85, 115, 0.20)";
      }
    },

    sizeAsPx() {
      const sizes = {
        small: "6px",
        medium: "10px",
        large: "15px",
      };

      return sizes[this.size];
    },
  },
};
</script>

<style scoped>
.progress-container {
  width: 100%;
}

.progress-bar {
  overflow: hidden;
  border-radius: 20px;
}

.progress-bar-inner {
  height: 100%;
  border-radius: 20px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.indeterminate {
  animation: indeterminateAnimation 1s linear infinite;
  width: 50% !important;
}

@keyframes indeterminateAnimation {
  0% {
    transform: translateX(-100%);
  }
  50% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(100%);
  }
}
</style>
