<script>
/*
    Note: More on our version of chart.js here:
    https://www.chartjs.org/docs/2.9.4/charts/doughnut.html
  */
import {Doughnut} from "vue-chartjs";

export default {
  extends: Doughnut,

  name: "SixDoughnutChart",

  props: {
    data: {
      /**
       * Schema:
       * [
       * {
       * title: string,
       * value: number,
       * color: string, // predefined color or hex code
       * },
       * ]
       */
      type: Array,
      required: true,
      validator: (value) =>
        Array.isArray(value) &&
        value.every((item) => item.title && !isNaN(item.value) && item.color),
    },
    legend: {
      // Whether to show the legend or not
      type: Boolean,
      default: false,
    },
  },

  watch: {
    data: "renderDoughnutChart",
    legend: "updateLegendVisibility",
  },

  mounted() {
    this.renderDoughnutChart();
  },

  methods: {
    renderDoughnutChart() {
      const chartData = {
        labels: this.data.map((item) => item.title),
        datasets: [
          {
            data: this.data.map((item) => item.value),
            backgroundColor: this.data.map((item) =>
              this.getColor(item.color)
            ),
            hoverBackgroundColor: this.data.map((item) =>
              this.getColor(item.color)
            ),
            borderWidth: 0,
          },
        ],
      };

      this.renderChart(chartData, this.getChartOptions());
    },
    getColor(color) {
      // chart.js does not support color variables :/
      const colorVariables = {
        primary: "#034860",
        secondary: "#ebebeb",
        success: "#00c620",
        danger: "#ee5755",
        billing_blue: "#429cbf",
        light_blue: "#9EF0F9",
      };
      return colorVariables[color] || color;
    },
    getChartOptions() {
      const options = {
        responsive: true,
        maintainAspectRatio: false,
      };

      if (this.legend !== undefined) {
        options.legend = {display: this.legend};
      }

      return options;
    },
    updateLegendVisibility() {
      this.$data._chart.destroy();
      this.renderDoughnutChart();
    },
  },
};
</script>
