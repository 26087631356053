<template>
  <div
    class="select-container"
    :style="{width: fullWidth ? '100%' : 'fit-content'}">
    <div
      class="top-label sm-text"
      v-if="label && !leftLabel">
      {{ label }}
    </div>

    <div
      class="box"
      :style="{
        border: flat
          ? 'none'
          : isSelectOpen
          ? '1px solid #034860'
          : errorMessage
          ? '1px solid var(--color-danger)'
          : '1px solid #E9ECEF',
      }"
      @click="toggleSelect"
      @mouseover="onHover"
      @mouseleave="onHoverEnd">
      <div
        v-if="label && leftLabel"
        :class="[{'left-label': leftLabel}]"
        class="uih6 grey">
        {{ label }}
      </div>

      <div
        class="selected-items"
        :class="{'disabled': disabled, 'with-left-label': leftLabel}">
        <div
          class="uih6"
          v-if="selectedItems.length === 0">
          {{ placeholder }}
        </div>

        <div
          v-for="(item, index) in selectedItems"
          :key="index"
          class="selected-item"
          :class="{multiple: multiple}"
          @click="multiple && clickToRemove ? removeItem(item) : null">
          <six-icon
            v-if="multiple && clickToRemove"
            name="plusSign"
            :color="disabled ? 'grey' : 'primary'"
            class="item-icon remove"
            :style="{transform: 'rotate(45deg)'}"></six-icon>
          <span class="uih6">{{ item.label }}</span>
          <span v-if="multiple && index !== selectedItems.length - 1">,</span>
        </div>
      </div>
      <six-icon
        name="caret"
        :color="disabled ? 'grey' : 'primary'"
        class="item-icon"
        :style="{
          transform: isSelectOpen ? 'rotate(180deg)' : 'rotate(0)',
        }"></six-icon>
    </div>
    <div
      class="select"
      v-show="isSelectOpen && !disabled"
      id="select"
      :style="{marginTop: isSelectOpen ? '8px' : '0'}">
      <transition name="select-fade">
        <div class="options-wrapper">
          <div v-if="isSelectOpen">
            <input
              v-if="searchable"
              type="text"
              class="search-input"
              placeholder="Search"
              v-model="searchText" />
            <div
              v-for="(item, index) in getDisplayOptions()"
              :key="index"
              @click="handleOptionClick(item)"
              :class="{selected: isSelected(item)}"
              class="uih6"
              @mouseover="onItemHover(item)"
              @mouseleave="onItemHoverEnd">
              {{ shownItemLabel(item) }}
            </div>
          </div>
        </div>
      </transition>
    </div>
    <div class="error-message">
      {{ errorMessage }}
    </div>
  </div>
</template>

<script>
import SixIcon from "./sixIcon.vue";

export default {
  name: "SixSelect",

  components: {SixIcon},

  props: {
    options: {
      type: Array,
      required: true,
    },
    value: {
      type: [String, Array],
      default: "",
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    flat: {
      /**
       * If true, select box will have no border
       */
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: "Select",
    },
    multiple: {
      /**
       * If true, user can select multiple options
       * and the return value will be an array of strings
       */
      type: Boolean,
      default: false,
    },
    clickToRemove: {
      /**
       * If true, user can remove selected items by clicking on them
       * without opening the options menu
       */
      type: Boolean,
      default: false,
    },
    searchable: {
      /**
       * If true, user can search for options
       */
      type: Boolean,
      default: false,
    },
    leftLabel: {
      type: Boolean,
      default: false,
    },
    label: {
      /**
       * The label to display on the left side
       * of the select box if leftLabel is true
       */
      type: String,
      default: "",
    },
    errorMessage: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      isSelectOpen: false,
      selectedItems: [],
      searchText: "",
      hoveredItem: undefined,
    };
  },

  computed: {
    filteredOptions() {
      return this.options.filter((item) =>
        item.label.toLowerCase().includes(this.searchText.toLowerCase())
      );
    },

    shownItemLabel() {
      return (item) => {
        if (this.hoveredItem === item) {
          return item.hoverLabel || item.label;
        }
        return item.label;
      };
    },
  },

  watch: {
    value: {
      immediate: true,
      handler(newValue) {
        if (this.multiple) {
          this.selectedItems = this.options.filter((item) =>
            newValue.includes(item.value)
          );
        } else {
          this.selectedItems = this.options.filter(
            (item) => item.value === newValue
          );
        }
      },
    },
  },

  created() {
    document.addEventListener("click", (e) => {
      if (!this.$el.contains(e.target)) {
        this.isSelectOpen = false;
      }
    });
  },

  methods: {
    toggleSelect() {
      if (!this.disabled) {
        this.isSelectOpen = !this.isSelectOpen;
        this.$nextTick(() => {
          if (this.isSelectOpen) {
            this.adjustDropdownPosition();
          }
        });
      }
    },

    // Adjusts dropdown position if it goes out of viewport
    adjustDropdownPosition() {
      const dropdown = this.$el.querySelector(".select");
      const rect = dropdown.getBoundingClientRect();
      const viewportWidth = window.innerWidth;

      if (rect.right > viewportWidth) {
        dropdown.style.right = "-50px";
        dropdown.style.left = "auto";
      } else if (rect.left < 0) {
        dropdown.style.left = "0px";
        dropdown.style.right = "auto";
      }
    },

    onHover() {
      if (!this.disabled) {
        this.$emit("hover");
      }
    },

    onHoverEnd() {
      if (!this.disabled) {
        this.$emit("hoverEnd");
      }
    },

    onItemHover(item) {
      setTimeout(() => {
        this.hoveredItem = item;
      }, 500);
    },

    onItemHoverEnd() {
      this.hoveredItem = null;
    },

    isSelected(item) {
      return this.selectedItems.some(
        (selectedItem) => selectedItem.value === item.value
      );
    },
    handleOptionClick(item) {
      if (this.multiple) {
        this.toggleSelected(item);
      } else {
        this.selectItem(item);
      }
    },
    getDisplayOptions() {
      return this.searchable ? this.filteredOptions : this.options;
    },
    selectItem(item) {
      this.selectedItems = [item];
      this.$emit("input", item.value);
      this.$emit("change", item.value);
      this.isSelectOpen = false;
    },
    toggleSelected(item) {
      const index = this.selectedItems.findIndex(
        (selectedItem) => selectedItem.value === item.value
      );
      if (index === -1) {
        this.selectedItems.push(item);
      } else {
        this.selectedItems.splice(index, 1);
      }
      this.$emit(
        "input",
        this.selectedItems.map((selectedItem) => selectedItem.value)
      );
    },
    removeItem(item) {
      const index = this.selectedItems.findIndex(
        (selectedItem) => selectedItem.value === item.value
      );
      if (index !== -1) {
        this.selectedItems.splice(index, 1);
      }
    },
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

.select-container {
  position: relative;
  cursor: pointer;
  max-width: 100%;
  background: transparent;

  .options-wrapper {
    max-height: 400px;
    overflow-y: auto;
    width: inherit;
  }

  .select {
    display: flex;
    position: absolute;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: -webkit-fill-available;
    width: -moz-available;
    width: stretch;
    right: auto;
    left: auto;

    padding: 4px 16px;
    gap: 8px;

    background: #fff;
    box-shadow: 0px 10px 13px 0px rgba(17, 38, 146, 0.05);

    border: 1.5px solid #f5f5f5;
    border-radius: 4px;

    transition: margin-top 0.3s ease;
    z-index: 9999;
  }
  .select div:not(:first-child) {
    padding: 8px 0px 4px 0px;
    white-space: normal;
  }

  .select div.selected {
    background-color: transparent;
  }
}

.box {
  background: #fff;
  display: flex;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  justify-content: flex-start;
}

.select-fade-enter-active,
.select-fade-leave-active {
  transition: opacity 0.3s;
}

.select-fade-enter,
.select-fade-leave-to {
  opacity: 0;
}

.placeholder {
  color: var(--color-primary-solid);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%; /* 20px */
}

.disabled .placeholder {
  color: var(--color-deactive-text);
}
.selected-items {
  display: flex;
  height: 44px;
  padding: 4px 12px;
  align-items: center;
  text-align: center;
  overflow: hidden;
  cursor: pointer;

  color: var(--color-primary-solid);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border: none;

  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none;
  flex: 4;
  white-space: nowrap;
}

.selected-items.disabled {
  color: var(--color-deactive-text);
  cursor: not-allowed;
}
.selected-item {
  display: flex;
  justify-self: center;
  align-items: center;

  margin-right: 5px;
  padding: 4px 3px;
  border-radius: 4px;

  background-color: rgba(255, 255, 255, 0.05);
  white-space: nowrap;
}

.selected-item .remove {
  margin-bottom: 5px;
  margin-right: 5px;
  cursor: pointer;
}

.fa-times {
  font-size: 15px;
}

.search {
  width: 100%;
  padding: 5px;

  border: 1px solid #ccc;
  border-left: none;
  border-right: none;
  border-top: none;
  margin-top: 5px;
  margin-bottom: 5px;
}

.search-input {
  display: block;
  width: 100%;
  border: none;

  padding: 4px 0px 6px 0px;
  border-bottom: 1px solid var(--color-deactive-text);
  outline: none;

  color: #034860;

  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%; /* 20px */
}

.search-input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--color-deactive-text);
  opacity: 1; /* Firefox */
}

.search-input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: var(--color-deactive-text);
}

.search-input::-ms-input-placeholder {
  /* Microsoft Edge */
  color: var(--color-deactive-text);
}

.search-input:focus {
  border-bottom-color: var(--color-primary-solid);
}

.item-icon {
  height: 24px;
  width: 24px;
  flex-shrink: 0;
  margin-left: auto;
  margin-top: 4px;
}

.top-label {
  border-radius: 4px 0px 0px 4px;
  margin: 4px 0;
}

.left-label {
  border-radius: 4px 0px 0px 4px;
  background: #f2f4f7;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: stretch;
  flex: 1;
}

.selected-items.with-left-label {
  padding: 0px;
}

.error-message {
  color: var(--color-danger);
  font-size: 14px;
  margin-top: 4px;
  padding-left: 12px;
  background-color: transparent;
}

@media (max-width: 1440px) {
  .left-label {
    padding: 11px;
  }

  .selected-items {
    height: 40px;
  }
}
</style>
