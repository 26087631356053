<template>
    <div
        :class="{
            'sf-card': true,
            'sf-card__padding': !noPadding,
            'full-height': fullHeight,
        }"
    >
        <div v-if="loading" class="sf-card__loading-hover">
            <sf-icons scale="3" spin name="spinner" />
            {{ loadingText }}
        </div>
        
      <beta-badge v-if="beta" />
        <div
            :class="{
                'sf-card__container': true,
                'sf-card__blur': loading,
            }"
        >
            <div class="va-row row wrap" v-if="title">
                <div class="flex xs12 xl6">
                    <header v-if="title">{{ title }}</header>
                </div>
                <div class="flex xs12 xl6 text-right">
                    <slot name="actions"></slot>
                </div>
            </div>
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: "sf-card",
    props: {
        noPadding: {
            type: Boolean,
            default: false,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        loadingText: {
            type: String,
            default: "",
        },
        fullHeight: {
            type: Boolean,
            default: false,
        },
        title: {
            type: String,
            default: "",
        },
        beta: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style scoped>
.sf-card {
    background-color: white;
    border-radius: 0.375rem;
    position: relative;
}

.full-height {
    height: 100%;
}

.sf-card__padding {
    padding: 1.25rem 1.25rem 1.25rem;
}

.sf-card__blur {
    filter: blur(3px);
}

.sf-card__loading-hover {
    position: absolute;

    z-index: 20;

    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

header {
    font-weight: bold;
    font-size: 1.5rem;
}
</style>