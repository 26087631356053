var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{
  'assets-button': true,
  'border': _vm.border,
  'small': _vm.small,
  'color__default': _vm.color === 'default',
  'color__red': _vm.color === 'red',
  'color__green': _vm.color === 'green',
  'color__blue': _vm.color === 'blue',
  'color__dark-gray': _vm.color === 'dark-gray',
  'color__cream': _vm.color === 'cream',
  'disabled': _vm.disabled
},on:{"click":_vm.clickEvent}},[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }