<template>
  <div class="toast-container">
    <div
      v-for="(toast, index) in toasts"
      :key="index">
      <six-toast
        :type="toast.type"
        :title="toast.title"
        @close="hideToast(toast)">
        <template v-slot:message>
          <div v-html="toast.message"></div>
        </template>
      </six-toast>
    </div>
  </div>
</template>

<script>
import SixToast from "./sixToast.vue";
export default {
  name: "SixToastContainer",
  components: {SixToast},

  data() {
    return {
      toasts: [],
    };
  },

  created() {
    this.$root.$on("addToast", (toast) => {
      this.showToast(toast);
    });
  },

  methods: {
    showToast(toast) {
      this.toasts.push(toast);
      if (toast.timeout === 0) return;
      setTimeout(() => {
        this.hideToast(toast);
      }, toast.timeout);
    },
    hideToast(toast) {
      this.toasts = this.toasts.filter((t) => t !== toast);
    },
  },
};
</script>

<style scoped>
.toast-container {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 2147483646;
}
</style>
