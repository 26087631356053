/* eslint-disable */

import hasGroup from "../../../utils/hasGroup"

export const navigationRoutes = {
  root: {
    name: "/",
    displayName: "navigationRoutes.home",
  },
  routes: [
    {
      name: 'dashboard',
      displayName: 'menu.dashboard',
      target: "/",
      meta: {
        iconClass: 'va-icon fa fa-home',
      },
    },
    {
      name: 'assets',
      displayName: 'menu.assets',
      target: "/assets",
      meta: {
        iconClass: 'va-icon fa fa-cubes',
      },
    },
    {
      name: "datapools",
      displayName: "menu.sim.datapools",
      target: "/datapools",
      meta: {
        iconClass: 'va-icon fa fa-globe ',
      },
    },
    {
      name: 'usage',
      displayName: 'menu.usage.title',
      meta: {
        iconClass: 'va-icon fa fa-history',
      },
      disabled: true,
      children: [
        {
          name: "usage",
          displayName: "menu.usage.total",
        },
        {
          name: "summary",
          displayName: "menu.usage.summary",
        },
      ],
    },
    {
      name: 'billing',
      displayName: 'menu.billing.title',
      meta: {
        iconClass: 'va-icon fa fa-credit-card',
      },
      disabled: true,
      children: [
        {
          name: "addcredits",
          displayName: "menu.billing.addcredits",
        },
        {
          name: "billingDetails",
          displayName: "menu.billing.details",
          target: "/billing"
        },
      ],
    },
    {
      name: 'help',
      displayName: 'menu.help.title',
      meta: {
        iconClass: 'va-icon fa fa-question-circle',
      },
      disabled: true,
      children: [
        {
          name: "faq",
          displayName: "menu.help.faq",
        },
        {
          name: "community",
          displayName: "menu.help.community",
          externalLink: "https://community.sixfab.com/c/sixfab-connect/25"
        },
        {
          name: "contact",
          displayName: "menu.help.contact",
          externalLink: "https://sixfab.com/contact/"
        }
      ],
    },
  ],
};
