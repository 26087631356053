<template>
  <div class="banner">
    <h5>
      Introducing the new Sixfab Connect: a modern interface, and new features
      like in-app notifications and team management.
      <span
        class="button"
        @click="showChangelogModal">
        Learn More
      </span>
    </h5>

    <six-modal
      ref="changelogModal"
      title="Sixfab Connect Changelog"
      class="change-modal">
      <div class="sm-text modal-content">
        <h5>User Interface</h5>
        We’ve updated the user interface to make it more modern and
        user-friendly. Some sidebar items have been reorganized for easier
        navigation.
        <ul>
          <li>
            - <strong>Data Pools</strong> and
            <strong>Total Data Usage</strong> are now under the ‘Cellular’
            menu.
          </li>
          <li>
            - <strong>Cost Summary</strong> is now under the ‘Billing’ menu.
          </li>
          <li>
            - <strong>API Keys</strong> are now directly accessible from the
            sidebar.
          </li>
        </ul>
        <h5>Overview Page</h5>
        The <strong>Dashboard</strong> has been replaced with
        <strong>Overview</strong>, which provides more summarized information
        about your assets and account, making it easier to see important
        details at a glance.
        <h5>Data Usage Information</h5>
        We’ve optimized data usage monitoring. Loading usage information for
        your account and assets is now faster and smoother.
        <h5>Notifications</h5>
        A new in-app notification system has been added, allowing you to
        receive updates directly on the platform.

        <h5>Team Management</h5>
        We’ve introduced a new team management feature. You can now add and
        remove permissions for other accounts, enabling them to manage your
        Sixfab Connect account. You can easily control which team members have
        access and what permissions they hold.

        <hr />
        If you have any questions, reach out to us
        <a href="https://sixfab.com/contact">here</a>.
      </div>
    </six-modal>
  </div>
</template>

<script>
export default {
  name: "ChangeBanner",

  computed: {
    seenChangelog() {
      return document.cookie.includes("changelog_seen=true");
    },
  },

  mounted() {
    if (!this.seenChangelog) {
      this.showChangelogModal();

      let expires = new Date();
      expires.setTime(expires.getTime() + 2592000 * 1000); // 30 days in milliseconds
      document.cookie = `changelog_seen=true; expires=${expires.toUTCString()}; path=/`;
    }
  },

  methods: {
    showChangelogModal() {
      this.$refs.changelogModal.show();
    },
  },
};
</script>

<style>
.banner {
  background-color: var(--color-billing-blue); /*#429cbf*/
  padding: 10px;
  text-align: center;
  user-select: none;
}

.banner > h5 {
  color: #fff !important;
  font-weight: 400 !important;
}

h5 {
  margin-top: 20px !important;
}

h5:first-child {
  margin-top: 0 !important;
}

.button {
  cursor: pointer;
  font-weight: 600 !important;
}

.change-modal > .modal-content {
  max-width: 508px;
  text-align: left !important;
}

a {
  color: var(--color-hyperlink);
}
</style>
