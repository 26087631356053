<template>
  <va-dropdown class="profile-dropdown" v-model="isShown" boundary-body offset="0, 16px">
    <span class="profile-dropdown__anchor" slot="anchor" :style="{color: this.$themes.primary}">
      <slot />
      <va-icon
        class="pa-1"
        :name="`fa ${isShown ? 'fa-angle-up' :'fa-angle-down'}`"
        style="color: white"
      />
    </span>
    <div class="profile-dropdown__content pl-4 pr-4 pt-2 pb-2" style="width: auto;">
      <router-link
        v-for="option in options"
        :key="option.name"
        :to="{name: option.redirectTo}"
        class="profile-dropdown__item pt-1 pb-1 mt-2 mb-2"
      >{{ option.text }}</router-link>

      <a
        href="/#/pricing"
        rel="noopener noreferrer"
        class="profile-dropdown__item pt-1 pb-1 mt-2 mb-2"
      >
        {{ $t("navbar.pricing") }}
      </a>

      <a
        href="/#/developer"
        rel="noopener noreferrer"
        class="profile-dropdown__item pt-1 pb-1 mt-2 mb-2"
      >
        {{ $t("navbar.developer") }}
      </a>

      <a
        class="profile-dropdown__item pt-1 pb-1 mt-2 mb-2"
        @click="logout()"
      >{{ $t("navbar.logout") }}</a>
    </div>
  </va-dropdown>
</template>

<script>
export default {
  name: "menu-dropdown",
  data() {
    return {
      isShown: false
    };
  },
  props: {
    options: {
      type: Array,
      default: () => [
      ]
    }
  },
  methods: {
    logout() {
      this.$store.dispatch("auth_logout", true);
    }
  }
};
</script>

<style lang="scss">
.profile-dropdown {
  cursor: pointer;

  &__anchor {
    color: $vue-green;
  }
  .va-dropdown-popper__anchor {
    display: flex;
    justify-content: flex-end;
  }
  &__content {
    background-color: $dropdown-background;
    box-shadow: $gray-box-shadow;
    border-radius: 0.5rem;
    width: 8rem;
  }
  &__item {
    display: block;
    color: $vue-darkest-blue;

    &:hover,
    &:active {
      color: $vue-green;
    }
  }

  .va-dropdown__anchor {
    display: inline-block;
  }
}
</style>
