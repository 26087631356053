<template>
  <div class="slider">
    <input
      class="slider-input"
      type="range"
      :min="options.length !== 0 ? 0 : min"
      :max="options.length !== 0 ? options.length - 1 : max"
      :value="options.length !== 0 ? options.indexOf(value) : value"
      @input="
        ($event) =>
          $emit('input', getSelectedOption(parseInt($event.target.value)))
      "
      :disabled="disabled" />
  </div>
</template>

<script>
export default {
  name: "SixSlider",

  props: {
    disabled: {
      type: Boolean,
      default: false,
    },

    value: {
      type: Number,
      default: 0,
    },

    options: {
      type: Array,
      default: () => {
        let options = [];
        for (let i = 0; i <= 100; i++) {
          options.push(i);
        }
        return options;
      },
    },

    min: {
      type: Number,
      default: 0,
    },

    max: {
      type: Number,
      default: 100,
    },

    color: {
      type: String,
      default: "primary",
    },
  },

  computed: {
    dynamicColor() {
      const colors = {
        primary: "var(--color-primary-solid)",
        secondary: "var(--color-secondary)",
        success: "var(--color-success)",
        danger: "var(--color-danger)",
      };

      return colors[this.color] || this.color;
    },
  },

  created() {
    // set css variable for thumb color
    this.$nextTick(() => {
      const sliderInput = this.$el.querySelector(".slider-input");
      if (sliderInput) {
        sliderInput.style.setProperty("--thumb-color", this.dynamicColor);
      }
    });
  },

  methods: {
    getSelectedOption(index) {
      return this.options[index];
    },
  },
};
</script>

<style scoped>
.slider {
  border: none;
  background: transparent;
}

.slider-input {
  height: 5px;
  border-radius: 50px;
  background: rgb(195, 202, 230);
  width: 100%;
  -webkit-appearance: none;
}

.slider-input::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0px 2px 4px 0px rgba(58, 87, 232, 0.3);
  background: #fff;
  border: 4px solid var(--thumb-color);
}

.slider-input::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0px 2px 4px 0px rgba(58, 87, 232, 0.3);
  background: #fff;
  border: 4px solid var(--thumb-color);
}

.slider-input:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.slider-input:disabled::-webkit-slider-thumb {
  cursor: not-allowed;
}

.slider-input:disabled::-moz-range-thumb {
  cursor: not-allowed;
}
</style>
