<template>
  <div class="beta-badge">Beta</div>
</template>

<script>
export default {

}
</script>

<style scoped>
.beta-badge {
  position: absolute;
  left: 0px;
  top: 0px;
  background-color: rgba(247,0,0, 1);
  text-align: center;
  color: white;
  padding: 0.1rem 0.5em;
  border-top: 0px;
  line-height: 15px;
  font-size: 15px;
  margin: 0;
  border-radius: 0.375rem 0 10px 0
}
</style>