<template>
  <div v-if="showHeader">
    <div v-if="isAccountSuspended && !isRemoteTerminalWindow" class="warning">
      Your account is suspended. Please add a valid payment method and credits to your account.
    </div>
  </div>
</template>

<script>
import mapState from "vuex"

export default {
  name: "sf-header",
  computed: {
    isAccountSuspended(){
       return this.$store.state.auth.accountSuspended
    },
    isRemoteTerminalWindow() {
        return this.$route.name === "terminal"
    }
  },

  data() {
    return {
      showHeader: true
    }
  },

  mounted() {
    if(window.location.href.includes("/#/terminal")){
        /* don't show headers on terminal page*/
        this.showHeader = false
    }

    if (!this.$store.getters.isAuthenticated){
      return // skip for not authenticated users
    }
    
    if(this.isAccountSuspended !== undefined){
      return // pass account suspension status check, its already done
    }

    this.axios.get("/account").then(resp => {
      this.$store.commit("updateSuspensionStatus", resp.data.suspended)
    });
  }
  }
</script>

<style scoped>
.warning {
  background-color: red;
  text-align: center;
  font-size: 20px;
  color: white;
  padding: 5px 0;
}

.sixfab-color {
  background-color: #429CBF;
  text-align: center;
  font-size: 20px;
  color: white;
  padding: 5px 0;
}

.sixfab-color a {
  color: white;
  text-decoration: underline;
}
</style>
